.bidding-box {
  font-size: 2rem;

  @media (max-width: $screen-xs-max) {
    padding: 0 2.5rem;
    margin: 0 -2.5rem;
  }

  .current-bid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 1.5rem 2rem;

    @media (max-width: $screen-xs-max) {
      display: block;
      padding: 0 2.5rem;
    }

    &-value {
      display: inline-block;
      flex: 1;
      float: none;
      padding: 0;
      vertical-align: middle;

      @media (max-width: $screen-xs-max) {
        display: block;
        width: 100%;
        padding: 2rem 0 0.5rem;
      }

      &.new-price {
        .bid-amount {
          color: $color-red;
          text-shadow: 0 0 0.2rem rgba($color-red, 0.2);
          transition: none;
        }
      }
    }

    .bid-amount {
      margin: 0 0 0 1rem;
      font-size: 2.8rem;
      font-weight: $medium-font;
      color: $text-color;
      transition: all 0.3s ease;

      @media (max-width: $screen-md-max) {
        display: inline-block;
        margin: 0 0 0 1rem;
        font-size: 2.2rem;
      }
    }
  }

  .highest-bidder {
    padding: 0 1.5rem 2rem;
    font-size: 2rem;
    color: $color-dark-green;
    text-align: left;
  }

  .reserve-price {
    display: inline-block;
    float: none;
    color: $color-red;
    text-align: right;
    text-transform: uppercase;
    vertical-align: middle;

    @media (max-width: $screen-xs-max) {
      margin: 0 0 1.5rem;
    }

    .tooltip {
      text-align: center;
    }

    &--green {
      color: $background-green;
    }
  }

  &__form {
    padding: 0;
    color: $text-dark;
    border: 0.2rem solid $brand-primary;

    @media (max-width: $screen-xs-max) {
      width: calc(100% + 5rem);
      margin: 0 -2.5rem;
      border-right-width: 0;
      border-left-width: 0;
    }

    .increase-bid,
    .buy-now {
      padding: 2rem;
      font-size: 1.7rem;

      .label {
        font-weight: $medium-font;
      }

      @media (max-width: $screen-xs-max) {
        display: block;
        padding: 2rem 2.5rem;
      }
    }

    .increase-bid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.1rem solid $border-color;

      @media (max-width: $screen-md-max) {
        display: block;
      }

      @media (max-width: $screen-xs-max) {
        padding: 2rem 2.5rem;
      }

      .label,
      .buttons {
        display: inline-block;

        @media (max-width: $screen-md-max) {
          display: block;
        }
      }

      .label {
        @media (max-width: $screen-md-max) {
          margin-bottom: 1.5rem;
        }

        @media (max-width: $screen-xs-max) {
          margin-bottom: 1rem;
        }
      }

      button {
        padding-right: 1.3rem;
        padding-left: 1.3rem;
        font-size: 1.6rem;
        color: $color-white;
        background: $btn-secondary;
        transition: all 0.3s ease;

        @media (width <= 400px) {
          flex: 1;
          padding-right: 0;
          padding-left: 0;
        }

        &:hover {
          color: $color-white;
          background: $brand-primary;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &:not(:first-of-type) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &:not(:last-of-type) {
          border-right: 0.1rem solid $border-color-dark;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      @media (max-width: $screen-xs-max) {
        display: block;

        .label,
        .buttons {
          display: block;
        }
      }

      .buttons {
        @media (width <= 400px) {
          display: flex;
        }
      }
    }

    .enter-bid {
      padding: 2rem;

      @media (max-width: $screen-xs-max) {
        padding: 2rem 2.5rem;
      }

      &__inner {
        font-size: 1.7rem;
      }

      .label {
        display: block;
        width: 100%;
        margin: 0 0 1.5rem;
        font-weight: $medium-font;
      }

      .bid-value {
        align-items: center;
        width: 100%;
        padding: 0;

        @media (width >= 450px) {
          display: flex;
        }

        input {
          flex: 1;
          width: auto;
          border-radius: $border-radius 0 0 $border-radius;
        }

        button {
          white-space: nowrap;
          border-radius: 0 $border-radius $border-radius 0;

          @media (width <= 449px) {
            margin-top: 1.5rem;
          }
        }

        input,
        button {
          @media (width <= 449px) {
            width: 100%;
            border-radius: $border-radius;
          }
        }
      }
    }

    .bid-value-error {
      padding: 0 2rem;
      margin: 1rem 0 0;
      font-size: 1.4rem;
      color: $color-red;
    }

    .notes {
      padding: 0 2rem 2rem;
      font-size: 1.5rem;
      line-height: 1.2;
      color: $text-color;

      @media (max-width: $screen-xs-max) {
        padding: 0 2.5rem 2rem;
      }
    }

    .buy-now {
      button {
        width: 100%;

        @media (max-width: $screen-sm-max) and (width >= 450px) {
          width: auto;
        }
      }

      .notes {
        width: 100%;
        padding: 0;
        margin: 1.5rem 0 0;
      }
    }
  }

  &__meta {
    padding-top: 2.5rem;
    clear: both;
    font-size: 1.7rem;

    .meta-row {
      display: block;

      &:not(:last-child) {
        margin: 0 0 1rem;
      }
    }

    .label,
    .value {
      display: inline-block;
      line-height: 1.1em;
      vertical-align: top;
    }

    .label {
      min-width: 18rem;
      padding: 0;
      margin: 0;
      font-weight: $medium-font;
    }

    .bids-list {
      padding-left: 1rem;
      margin-left: 1rem;
      border-left: 0.1rem solid $border-color;
    }
  }
}
