.bidding-result-modal {
  .modal__frame {
    width: 90%;
    max-width: 64rem;
    padding-bottom: 0.5rem;
  }

  .modal__body {
    overflow: hidden;

    .text-content {
      font-size: $body-font-size;
    }
  }

  &__content {
    margin: 0 0 3rem;
    font-size: 0;
    text-align: center;

    .img-container {
      display: inline-block;
      max-width: 12.1rem;
      vertical-align: middle;

      img {
        width: 100%;
        border-radius: 0.5rem;
      }
    }

    .data-container {
      display: inline-block;
      width: calc(100% - 12.1rem);
      padding-left: 3.2rem;
      text-align: left;
      vertical-align: middle;

      .title {
        margin-bottom: 1.5rem;
        font-size: 2.2rem;
        font-weight: $medium-font;
        text-align: left;
      }

      .svg-container {
        display: inline-block;
        margin-right: 1.1rem;
        color: $color-dark-green;
        vertical-align: middle;

        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      .price-text {
        display: inline-block;
        font-size: 1.7rem;
        font-weight: $regular-font;
        vertical-align: middle;

        span {
          font-weight: $medium-font;
        }
      }

      .bids-count {
        display: inline-block;
        padding-left: 1rem;
        margin-left: 1rem;
        font-size: 1.7rem;
        font-weight: $medium-font;
        border-left: 0.1rem solid $border-color;
      }
    }
  }

  &__actions {
    text-align: center;
  }
}
