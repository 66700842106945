.property-box {
  position: relative;
  cursor: pointer;

  a {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &.not-sold {
    margin-bottom: 6.9rem !important;
  }

  &__thumbnail {
    position: relative;
    padding-top: 75%;
    overflow: hidden;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: $border-radius;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 4;
      width: 100%;
      height: 100%;
      content: "";
      background-color: $brand-primary;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: "";
      background: linear-gradient(
        to bottom,
        rgb(0 0 0 / 0.7) 0%,
        transparent 25%,
        transparent 55%,
        rgb(0 0 0 / 0.75) 100%
      );
    }

    &__price,
    &__bids {
      position: absolute;
      bottom: 2rem;
      z-index: 2;
      color: $color-white;
    }

    &__price {
      left: 2.5rem;

      p {
        text-shadow: 0.1rem 0.1rem 0.1rem rgba($color-black, 0.5);

        &:first-of-type {
          margin-bottom: 0.7rem;
          font-size: 1.5rem;
        }

        &:last-of-type {
          font-size: 1.7rem;
          font-weight: $medium-font;
        }
      }
    }

    &__bids {
      right: 2rem;
      font-size: 1.7rem;
      font-weight: $medium-font;
      text-shadow: 0.1rem 0.1rem 0.1rem rgba($color-black, 0.5);
    }

    &__button {
      position: absolute;
      top: 50%;
      z-index: 5;
      width: 100%;
      min-height: 5.5rem;
      text-align: center;
      opacity: 0;
      transition:
        transform 0.5s ease,
        opacity 0.5s ease;
      transform: translateY(400%);
    }
  }

  &__duplicate-button {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;

    .btn {
      z-index: 6;
    }
  }

  &__title {
    width: 100%;
    min-height: 7.6012rem; // padding-top + (2 * font-size * line-height)
    padding: 2rem 0 0;
    font-size: 1.7rem !important;
    font-weight: $medium-font;
    line-height: 1.474;
    color: $text-second-color;
    text-align: center;
    transition: color 0.3s ease;

    span {
      line-height: 1.474;
    }
  }

  &__starts {
    position: absolute;
    top: 2rem;
    z-index: 2;
    width: 100%;
    padding: 0 2rem;
    font-size: 1.5rem !important;
    color: $color-white;
    text-align: left;
    text-shadow: 0.1rem 0.1rem 0.1rem rgba($color-black, 0.5);

    &.bottom-right {
      top: auto;
      bottom: 2rem;
      text-align: right;

      p {
        text-shadow: 0.1rem 0.1rem 0.1rem rgba($color-black, 0.5);

        &:first-of-type {
          margin-bottom: 0.7rem;
          font-size: 1.5rem;
        }

        &:last-of-type {
          font-size: 1.7rem;
          font-weight: $medium-font;
        }
      }
    }
  }

  &__ends {
    padding: 1rem 0 2rem;
    font-size: 1.6rem;
    text-align: center;

    &.red {
      color: $color-red;
    }

    &.green {
      color: $color-dark-green;
    }
  }

  &__user-bid {
    margin: 0 0 2rem;
    text-align: center;

    .not-won-info {
      color: $color-red;

      .svg-container {
        margin-right: 1rem;
      }

      span {
        font-weight: $medium-font;
      }
    }

    p {
      display: inline-block;
      padding: 1.5rem 2rem;
      font-size: 1.7rem;
      font-weight: $medium-font;
      border: 0.2rem solid transparent;
      border-radius: $border-radius;
    }

    &.winning p {
      color: $color-dark-green;
      border-color: $color-dark-green;
    }

    &.losing p,
    &.ended p {
      color: $color-red;
      border-color: $color-red;
    }
  }

  &__countdown {
    text-align: center;
  }

  .delete-btn {
    position: absolute;
    top: 1.2rem;
    right: 2.7rem;
    z-index: 6;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;

    .icon {
      width: 2.7rem;
      height: 2.7rem;
      color: $color-white;
    }
  }

  &:hover {
    &.ended {
      .property-box__thumbnail,
      .property-box__title,
      .property-box__user-bid {
        opacity: 0.5;
      }

      .delete-btn {
        visibility: visible;
        opacity: 1;
      }
    }

    .property-box__thumbnail {
      &::before {
        opacity: 0.75;
      }

      .property-box__thumbnail__button {
        opacity: 1;
        transform: translateY(-50%);
      }
    }

    .property-box__title {
      color: $brand-primary;
    }
  }
}
