.open-days-cancel-modal {
  &__content {
    text-align: center;

    .text-content {
      margin: 0 0 2rem;
    }

    .btn {
      margin: 0 1.5rem;
    }

    @media (max-width: $screen-xs-max) {
      .btn {
        margin: 0 auto 2rem;

        &::after {
          content: "\A";
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
