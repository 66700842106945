.accordion {
  &__item {
    border-left: 0.2rem solid transparent;
    transition: all 0.3s ease;

    &--header {
      position: relative;
      display: flex;
      padding: 2.5rem 5.8rem 2.5rem 3rem;
      font-size: 1.9rem;
      font-weight: $medium-font;
      cursor: pointer;
      user-select: none;
      border-top: 0.1rem solid $border-color;
      outline: 0 none;
      transition: all 0.3s ease;

      @media (max-width: $screen-sm-max) {
        padding: 1.9rem 5.5rem 2rem 1.8rem;
        font-size: 1.6rem;
      }

      @media (max-width: $screen-xs-max) {
        padding: 1.9rem 5.5rem 2rem 2.3rem;
      }

      &-aside {
        position: relative;
        height: 1.1em;
        padding: 0 0 0 1rem;
        overflow: hidden;
        white-space: nowrap;

        .read-more,
        .read-less {
          display: inline-block;
          color: $brand-primary;
          vertical-align: baseline;
          transition: transform 0.3s ease;

          @media (max-width: $screen-xs-max) {
            display: none;
          }
        }

        .read-less {
          position: absolute;
          top: 0;
          left: 1rem;
          transform: translate(0, 110%);
        }

        .read-more {
          transform: translate(0, 0);
        }

        &.expanded {
          .read-less {
            transform: translate(0, 0);
          }

          .read-more {
            transform: translate(0, -110%);
          }
        }
      }

      &-content {
        flex: 1;
      }

      .icon {
        position: relative;
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        margin: 0 0 0 1rem;
        color: $brand-primary;
        vertical-align: middle;
        transition: all 0.3s ease;
        transform: rotate(0deg);
      }
    }

    &--content {
      display: none;
      padding: 0 3rem 3rem;

      p {
        line-height: 2.7rem;
      }
    }

    &.expanded {
      border-color: $brand-primary;

      .accordion__item--header {
        color: $brand-primary;

        .icon {
          transform: rotate(180deg);
        }
      }

      .accordion__item--content {
        display: block;
        text-align: justify;
      }
    }
  }
}
