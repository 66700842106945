.breadcrumbs {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  margin-bottom: 0;
  font-size: 0;
  color: $text-dark-light;
  list-style: none;
  background: $color-white;

  @media (max-width: $screen-sm-max) {
    padding: 2rem 0 1.9rem;
    border-bottom: 0.1rem solid $background-gray;
  }

  &__inner {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 auto;
  }

  .breadcrumb {
    display: inline-block;
    margin: 0 0.4rem 0 0;
    font-size: 1.6rem;
    color: inherit;
    vertical-align: middle;

    a {
      color: inherit;
    }

    .icon {
      width: 1.4rem;
      height: 1.4rem;
      margin: 0 1.2rem 0 0;
      color: inherit;
      vertical-align: baseline;
    }

    &:not(:last-of-type) {
      &::after {
        display: inline-block;
        margin: 0 0 0 0.4rem;
        vertical-align: baseline;
        content: "»";
      }
    }
  }
}
