.contact {
  p {
    margin: 0 0 1rem;
    font-size: 1.7rem;

    .icon {
      width: 1.4rem;
      height: 1.4rem;
      margin: 0 1rem 0 0;
      color: inherit;
      vertical-align: baseline;

      @media (max-width: $screen-xs) {
        margin: 0 0.5rem 0 0;
      }
    }

    &.bold {
      font-weight: $medium-font;
    }

    a {
      font-weight: $medium-font;
    }
  }

  .details-row {
    margin: 0 0 3rem;

    @media (max-width: $screen-sm-max) {
      margin: 0 0 1.5rem;
    }

    &:last-child {
      margin-bottom: 0;

      @media (max-width: $screen-sm-max) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__content {
    padding-bottom: 4rem;

    @media (max-width: $screen-sm-max) {
      padding-top: 3rem;
    }

    .section-heading {
      @media (max-width: $screen-sm-max) {
        margin: 0 0 2rem;
      }
    }

    .contact-form {
      label {
        @media (max-width: $screen-sm-max) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
