.notification-box {
  padding: 2rem;
  margin-bottom: 4rem;
  font-size: 0;
  border-radius: $border-radius;

  @media (max-width: $screen-xs-max) {
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
  }

  &.success {
    color: $color-dark-green;
    background: $background-light-green;
    border: 0.1rem solid $color-dark-green;
  }

  &.warning {
    color: $color-yellow;
    background: $background-yellow;
    border: 0.1rem solid $color-yellow;
  }

  &.danger {
    color: $color-red;
    background: $background-light-red;
    border: 0.1rem solid $color-red;
  }

  &.info {
    color: $color-second-gray;
    background: $background-gray-medium;
    border: 0.1rem solid $border-color;
  }

  h2 {
    margin-bottom: 2rem;
  }

  a,
  button {
    font-weight: $medium-font;
    color: inherit;
    text-decoration: none;

    &.btn-primary {
      margin-top: 2rem;
    }
  }

  .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: 0.1rem 2rem 0 0;
    color: inherit;
    vertical-align: top;
  }

  @media (max-width: $screen-xs-max) {
    > .icon {
      display: none;
    }
  }

  &__content {
    position: relative;
    display: inline-block;
    width: calc(100% - 4rem);
    font-size: 1.8rem;
    vertical-align: top;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }

    p {
      line-height: 1.438;

      &:not(:last-child) {
        margin-bottom: 1.8rem;

        @media (max-width: $screen-xs-max) {
          margin-bottom: 0.5rem;
        }
      }
    }

    div {
      & > br {
        display: none;
      }
    }

    .date {
      margin-bottom: 0.75rem;
      font-size: 1.5rem;
      opacity: 0.7;
    }

    .dismiss {
      position: absolute;
      top: 0;
      right: 0;

      .icon {
        width: 1.3rem;
        height: 1.3rem;
        margin: 0;
      }
    }
  }
}
