.properties-index {
  padding-bottom: 4rem;

  .properties {
    @media (max-width: $screen-sm-max) {
      padding-top: 2.5rem;
    }

    .section-heading {
      @media (max-width: $screen-xs-max) {
        margin: 0 0 1.5rem;
      }
    }

    .properties-column {
      @media (max-width: $screen-xs-max) {
        padding-top: 2.5rem;
        border-top: 0.1rem solid $background-gray;
      }
    }
  }

  .how-it-works {
    display: none;

    @media (width <= 769px) {
      display: block;
    }
  }
}
