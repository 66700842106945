.how-it-works-button {
  position: relative;
  display: block;
  width: 100%;
  padding: 2.9rem 8rem 2.9rem 3rem;
  cursor: pointer;
  background: $background-green;
  border-radius: $border-radius;

  .heading,
  .content {
    display: block;
    margin: 0;
  }

  .heading {
    margin: 0 0 0.8rem;
    font-size: 2.2rem;
    color: $color-white;
  }

  .content {
    color: $color-green;
  }

  .icon {
    position: absolute;
    top: calc(50% - 2.25rem);
    right: 2.5rem;
    width: 5rem;
    height: 4.5rem;
    color: $color-green;
  }

  &:hover {
    text-decoration: none;
    background: darken($background-green, 10%);

    .content {
      text-decoration: underline;
    }
  }

  &:focus {
    @include outer-glow($background-green);
  }
}
