.property-end-timer {
  padding: 0 0 3rem;
  border-bottom: 0.1rem solid $border-color;

  @media (max-width: $screen-xs-max) {
    padding: 0 2.5rem 2rem;
    margin: 0 -2.5rem;
  }

  .timer,
  .end-time {
    display: inline-block;
    vertical-align: middle;
  }

  .timer {
    margin: 0 1rem 0 0;

    @media (max-width: $screen-md-max) {
      margin: 0 0 2rem;
    }
  }

  .countdown__num {
    font-size: 2rem;
  }

  .end-time {
    @media (max-width: $screen-md-max) {
      display: block;
    }

    p {
      margin: 0 0 0.5rem;

      &.date {
        font-weight: $medium-font;

        span {
          text-transform: uppercase;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notification-box {
    margin-bottom: 0;
  }
}
