@import "variables";
@import "mixins";
@import "animations";
@import "bootstrap-loader";

html {
  min-height: 100vh;

  // Default font size is 16px, let's change it to 10px for the base resolution
  // and increase by one 1 each 185px
  font-size: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

body {
  width: 100%;
  max-width: 100vw;
  font-size: $body-font-size;
  font-weight: $regular-font;
  -webkit-overflow-scrolling: touch;

  @media (max-width: $grid-float-breakpoint-max) {
    width: 100vw;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button,
input,
textarea,
a {
  line-height: 1.1em;
}

::-ms-clear {
  width: 0;
  height: 0;
}

input {
  &[type="text"],
  &[type="password"],
  &[type="tel"],
  &[type="email"] {
    /* Remove iOS styles */
    appearance: none;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.container {
  @media (width >= 1200px) {
    width: 120rem;
  }

  @media (max-width: $screen-md-max) {
    width: 100%;
  }
}

@media (width >= 768px) {
  .col-sm-5ths {
    float: left;
    width: 20%;
  }
}

@media (width >= 992px) {
  .col-md-5ths {
    float: left;
    width: 20%;
  }
}

@media (width >= 1200px) {
  .col-lg-5ths {
    float: left;
    width: 20%;
  }
}

@media (max-width: $screen-xs-max) {
  .row {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }

  .container {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

section {
  &.padding {
    padding: 6rem 0;

    @media (max-width: $screen-xs-max) {
      padding: 2.5rem 0;
    }
  }
}

.section__title {
  margin-bottom: 5rem;
  font-size: 3.2rem;
  font-weight: $light-font;

  &.center {
    text-align: center;
  }

  @media (width <= 1024px) {
    font-size: 2.4rem;
    line-height: 1.35;
    text-align: center;
  }

  @media (max-width: $screen-xs-max) {
    margin-bottom: 2.5rem;
  }
}

a {
  transition: all 0.3s ease;

  &:focus {
    text-decoration: none;
    outline: 0.1rem solid rgba($brand-primary, 0.5);
    outline-offset: 0;
    transition: none;

    @include outer-glow($brand-primary);
  }
}

.text-medium {
  font-weight: $medium-font;
}

.text-orange {
  color: $brand-primary;
}

.text-red {
  color: $color-red;
}

.text-green {
  color: $color-dark-green;
}

.text-blue {
  color: $logo-blue;
}

.section-heading {
  margin: 0 0 3.5rem;
  font-size: 2.8rem;
  font-weight: 500;
  color: $text-second-color;

  @media (width <= 1024px) {
    font-size: 2.4rem;
  }

  &.center {
    text-align: center;
  }

  .icon {
    width: 1.1rem;
    height: 1.1rem;
    margin-left: 1rem;
    color: $brand-primary;
  }
}

.icon-in-circle {
  display: inline-block;
  padding: 2.5rem;
  margin-bottom: 4rem;
  border: 0.3rem solid $brand-primary;
  border-radius: 50%;

  .icon {
    width: 6.5rem;
    height: 6.5rem;
    color: $brand-primary;
  }
}

.row {
  &.no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > [class^="col-"],
    & > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

/* Honeypot */
.accept-terms {
  position: absolute;
  left: -9999rem;
}

strong,
b {
  font-weight: $medium-font;
}

@media (max-width: $screen-xs-max) {
  [class^="col-"] {
    padding: 0 2.5rem;
  }
}

.visuallyhidden {
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  padding: 0;
  margin: -0.1rem;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap; /* 1 */
  border: 0;
}

.upper {
  text-transform: uppercase;
}

.lg-backdrop {
  background-color: rgb(0 0 0 / 0.85) !important;
}

.lg-outer .lg-thumb-outer {
  background-color: rgb(0 0 0 / 0) !important;
}
