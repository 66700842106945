.my-account-index {
  &__content {
    position: relative;
    min-height: 46rem;
    padding: 4rem 0;

    @media (max-width: $grid-float-breakpoint) {
      padding: 2rem 0;
    }

    .notification-box {
      margin-bottom: 4rem;
    }

    &__password {
      padding: 4rem 0 1.5rem;
      margin-bottom: 4rem;
      border-bottom: 0.1rem solid $border-color;

      @media (max-width: $grid-float-breakpoint) {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 2rem;

        .section-heading {
          margin-bottom: 2.5rem;
        }
      }

      @media (min-width: $grid-float-breakpoint) {
        border-top: 0.1rem solid $border-color;
      }
    }

    .row-birth-date,
    .row-phone {
      @media (max-width: $grid-float-breakpoint) {
        margin: 0;

        div[class^="col-"] {
          padding-right: 0;
          padding-left: 0;

          .form-control {
            border-radius: 0;
          }

          &:first-child .form-control {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
          }

          &:last-child .form-control {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }

          &:not(:last-child) .form-control {
            border-right: 0;
          }
        }
      }
    }

    .btn-submit {
      @media (max-width: $screen-mobile) {
        display: block;
        width: 100%;
      }
    }
  }
}

.my-account-properties {
  &--grid {
    margin: 0 -1.5rem;
    font-size: 0;

    .property-box {
      display: inline-block;
      width: 25%;
      padding: 0 1.5rem;
      margin-bottom: 1.5rem;
      font-size: $body-font-size;
      vertical-align: top;

      @media (max-width: $screen-sm-max) {
        width: 50%;
      }

      @media (max-width: $screen-xs-max) {
        width: 100%;
      }
    }
  }

  &--list {
    margin-top: -2rem;

    .single-property {
      padding: 2rem 0;

      &:not(:first-of-type) {
        border-top: 0.1rem solid $border-color;
      }

      .property-container {
        @media (min-width: $screen-md-min) {
          display: flex;
          align-items: center;
        }

        .img-container {
          display: inline-block;
          max-width: 12.1rem;
          vertical-align: middle;

          img {
            width: 100%;
            border-radius: 0.5rem;
          }

          @media (max-width: $screen-sm-max) {
            width: 100%;
            max-width: 100%;
            margin-bottom: 1.5rem;
          }
        }

        .data-container {
          display: inline-block;
          width: calc(100% - 12.1rem);
          text-align: left;
          vertical-align: middle;

          @media (max-width: $screen-sm-max) {
            width: 100%;
          }

          @media (min-width: $screen-md-min) {
            padding-left: 3.2rem;
          }

          .title {
            margin-bottom: 1.5rem;
            font-size: 2.2rem;
            font-weight: $medium-font;
            text-align: left;
          }

          .svg-container {
            display: inline-block;
            margin-right: 1.1rem;
            color: $color-dark-green;
            vertical-align: middle;

            svg {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .price-text {
            display: inline-block;
            font-size: 1.7rem;
            font-weight: $regular-font;
            vertical-align: middle;

            span {
              font-weight: $medium-font;
            }
          }

          .bids-count {
            display: inline-block;
            padding-left: 1rem;
            margin-left: 1rem;
            font-size: 1.7rem;
            font-weight: $medium-font;
            border-left: 0.1rem solid $border-color;
          }
        }

        .btn-container {
          margin-top: 1.5rem;
          margin-left: 15.1rem;

          @media (min-width: $screen-md-min) {
            display: flex;
            justify-content: flex-end;
            margin-top: 0;
          }

          .btn {
            max-width: 100%;
          }

          @media (max-width: $screen-sm-max) {
            margin-left: 0;

            .btn {
              display: block;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
