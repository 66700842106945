.sign-in-form {
  .notification-box {
    margin-bottom: 4rem;
  }

  @media (max-width: $screen-xs-max) {
    .form-group {
      padding-bottom: 1rem;

      label {
        margin-bottom: 1rem;
      }
    }
  }

  .actions {
    @media (min-width: $screen-xs) {
      display: flex;
      align-items: center;
    }

    &__recovery {
      @media (min-width: $screen-xs) {
        flex: 1;
      }

      @media (max-width: $screen-xs) {
        margin-bottom: 1rem;
      }
    }
  }
}
