.svg-container {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;

  svg {
    display: inline-block;
    width: inherit;
    height: inherit;
    color: inherit !important;

    [style*="stroke:#"]:not(.nocolor),
    [style*="stroke: #"]:not(.nocolor),
    [stroke*="#"]:not(.nocolor),
    [style*="stroke:rgb"]:not(.nocolor),
    [style*="stroke: rgb"]:not(.nocolor),
    [stroke*="rgb"]:not(.nocolor) {
      stroke: currentColor !important;
      transition: stroke 0.5s ease;
    }

    [style*="fill:#"]:not(.nocolor),
    [style*="fill: #"]:not(.nocolor),
    [fill*="#"]:not(.nocolor),
    [style*="fill:rgb"]:not(.nocolor),
    [style*="fill: rgb"]:not(.nocolor),
    [fill*="rgb"]:not(.nocolor) {
      fill: currentColor !important;
      transition: fill 0.5s ease;
    }

    [fill*="none"] {
      fill: transparent;
    }

    [stroke*="none"] {
      stroke: transparent;
    }
  }
}
