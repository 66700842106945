.property {
  &__features {
    display: inline-block;
    font-size: 1.7rem;
    vertical-align: middle;

    @media (width <= 550px) {
      margin-top: 1rem;
    }
  }

  &__feature {
    display: inline-block;
    margin: 0 1.8rem 0 0;
    color: $text-dark;
    vertical-align: middle;

    .icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      color: inherit;
      vertical-align: middle;
    }

    .value {
      display: inline-block;
      margin: 0 0 0 0.5rem;
      vertical-align: middle;
    }
  }
}
