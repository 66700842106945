.properties-list {
  [class^="col-"] {
    display: inline-block;
    float: none;
    vertical-align: top;
  }

  .property-box {
    margin-bottom: 4rem;
  }

  .icon-container {
    display: inline-block;
    width: 12rem;
    height: 12rem;
    text-align: center;
    border: 0.2rem solid $color-red;
    border-radius: 50%;
    box-shadow: 0 0 0 0.01rem $color-red;
  }

  .loop-icon {
    position: relative;
    width: 5.9rem;
    height: 5.9rem;
    margin: 0;
    color: $color-red;
    transform: translateY(50%);
  }

  h2 {
    margin: 2rem auto;
  }

  p {
    font-size: 1.7rem;
  }
}
