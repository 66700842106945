.text-content {
  table {
    //@extend .table, .table-bordered;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  div {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    line-height: 1.529;
  }

  strong,
  b {
    font-weight: $medium-font;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  img.alignright {
    float: right;
    margin: 0 0 1rem 1rem;
  }

  img.alignleft {
    float: left;
    margin: 0 1rem 1rem 0;
  }

  img.aligncenter {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .alignright {
    float: right;
  }

  .alignleft {
    float: left;
  }

  .aligncenter {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  ul {
    padding: 0;
    list-style: none;

    & > li {
      position: relative;
      padding-left: 1.5rem;
      margin-bottom: 1.5rem;

      &::before {
        position: absolute;
        top: 0.9rem;
        left: 0;
        display: inline-block;
        width: 0.7rem;
        height: 0.7rem;
        content: "";
        background: $brand-primary;
        border-radius: 50%;
      }
    }
  }
}
