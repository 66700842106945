.manage-open-days-list {
  position: relative;
  min-height: 20rem;

  .heading {
    margin: 0 0 3rem;
    font-size: 2.2rem;

    @media (max-width: $grid-float-breakpoint-max) {
      font-size: 1.9rem;
    }
  }

  .bookings {
    padding-left: 0;
    list-style: none;

    .booking {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0;
      }

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .column {
    display: inline-block;
    float: none;
    font-size: $body-font-size;
    vertical-align: middle;

    @media (min-width: $grid-float-breakpoint) {
      padding-left: 0;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      &.label-column,
      &.time-column {
        display: block;
        width: 100%;
        padding: 0;
      }

      &.time-column {
        margin: 0 0 1.5rem;
      }
    }
  }

  .notifications-container {
    padding: 0;
  }

  .action {
    &-primary {
      color: $brand-primary;
    }

    &-green {
      color: $color-dark-green;
    }

    &-danger {
      color: $color-red;
    }

    &-secondary {
      color: $text-dark;
    }

    &.attended,
    &.not-attended {
      text-align: right;
    }

    &.attended {
      color: $color-dark-green;
    }

    &.not-attended {
      color: $color-red;
    }

    .icon {
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 1rem 0 0;
      vertical-align: middle;
    }
  }

  .info-row {
    font-size: 0;

    .date-column {
      @media (min-width: $grid-float-breakpoint) {
        padding-left: 0;
      }

      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 1rem;
      }
    }
  }

  .form-row {
    margin-top: 1.5rem;
    font-size: 0;

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0 2.5rem;
    }

    .label-column {
      padding-left: 0;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
