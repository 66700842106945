.register-thank-you {
  padding: 8rem 0;
  text-align: center;

  @media (max-width: $screen-xs-max) {
    padding: 3rem 0;
  }

  &__icon {
    width: 12rem;
    height: 12rem;
    margin-bottom: 3.5rem;
    color: $brand-primary;

    @media (max-width: $screen-xs-max) {
      width: 7.6rem;
      height: 7.6rem;
      margin-bottom: 2rem;
    }
  }

  .section-heading {
    margin: 0 0 3rem;

    @media (max-width: $screen-xs-max) {
      margin: 0 0 1.5rem;
    }
  }

  .text-content {
    p {
      &:not(:last-child) {
        margin-bottom: 3rem;

        @media (max-width: $screen-xs-max) {
          margin-bottom: 1.5rem;
        }
      }
    }

    .btn {
      @media (max-width: $screen-mobile) {
        width: 100%;
      }
    }
  }
}
