.property-location-tab {
  &__address {
    margin: 0 0 3.5rem;
    font-size: 2.2rem;
  }

  &__directions {
    margin: 4rem 0 0;

    h6 {
      margin: 0 0 1.5rem;
      font-size: 1.6rem;
      color: $text-second-color;
    }

    &-inner {
      display: flex;
      align-items: center;

      .input {
        flex: 1;
      }

      .button {
        margin: 0 0 0 2.5rem;
      }
    }
  }
}
