textarea {
  resize: none;
}

.form-label,
label {
  margin-bottom: 1.5rem;
  font-weight: $medium-font;
  line-height: 1.4286;

  &.required {
    &::after {
      color: $brand-primary;
      content: " *";
    }
  }
}

.form-control {
  font-size: 1.7rem;
  box-shadow: none;

  &:focus {
    outline: 0;
  }

  &[disabled] {
    color: #979797;
    -webkit-text-fill-color: #979797;
  }
}

.form-group {
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 1.5rem;

  @media (max-width: $screen-sm-max) {
    margin-bottom: 0.5rem;
  }

  .help-block {
    margin-bottom: 0;

    &--validation {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 1.3rem;

      @media (max-width: $screen-sm-max) {
        bottom: 0.2rem;
      }
    }
  }

  .input-container {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      width: 1.9rem;
      height: 1.9rem;
      color: #777;
      transform: translateY(-50%);
    }
  }

  &.has-error {
    .checkbox label {
      color: $text-color;
    }
  }

  &.with-tooltip {
    .tooltip {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%);

      .icon {
        display: block;
      }
    }
  }

  &.with-tooltip--top {
    .tooltip {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .dropdown {
    padding: 0;

    &__value {
      padding: 0 1.5rem;
    }
  }
}

select.form-control {
  position: relative;
  padding: 0.6rem 3.3rem 0.6rem 1.2rem;
  appearance: none;
  cursor: pointer;
  background: url("/static/vectors/select-arrow.svg") right 1.5rem center
    no-repeat;

  &::-ms-expand {
    display: none;
  }
}
