.property-photos {
  position: relative;

  @media (max-width: $screen-xs-max) {
    margin: 0 -2.5rem;
  }

  &__current {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    margin: 0;
    background-color: $background-gray-medium;
    background-image: url("/static/images/property-placeholder.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 2.5rem;
    }
  }

  .mobile-text {
    display: none;

    @media (max-width: $screen-xs-max) {
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      display: block;
      font-weight: $medium-font;
      color: $color-white;
    }
  }

  &__thumbnails {
    padding: 0 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &__thumbnail {
    width: calc(25% - 1rem);
    height: 0;
    padding: 0 0 calc(18.75% - 1rem);
    margin: 0 0.5rem 1rem;
    background-color: $background-gray-medium;
    background-image: url("/static/images/property-placeholder.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 0 none;

    &.show-more {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba($color-black, 0.7);
      }

      .show-more__inner {
        position: absolute;
        top: 50%;
        left: 50%;
        padding-top: 3rem;
        color: $color-white;
        user-select: none;
        transform: translate(-50%, -50%);

        span {
          color: inherit;
        }
      }

      .icon {
        position: absolute;
        top: 0;
        left: calc(50% - 1.2rem);
        width: 2.4rem;
        height: 2rem;
        color: inherit;
      }
    }
  }
}
