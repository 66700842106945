.home__current-auctions {
  padding: 6rem 0;

  @media (max-width: $grid-float-breakpoint-max) {
    padding: 3rem 0;
  }

  &__inner {
    position: relative;
    max-width: 143.5rem;
    margin: 0 auto;

    @media (max-width: $screen-sm-max) {
      padding-bottom: 5rem;
    }

    .slider {
      @media (width <= 1300px) {
        padding: 0 5rem;
      }

      @media (max-width: $screen-md-max) {
        padding: 0 2.5rem;
      }
    }

    .arrow {
      position: absolute;
      top: calc(50% - 11rem);
      width: 3.4rem;
      height: 6.4rem;
      color: $text-dark-light;
      cursor: pointer;
      transition: all 0.3s ease;

      &-left {
        left: 1.5rem;
      }

      @media (width <= 1024px) {
        top: calc(50% - 15rem);
      }

      @media (max-width: $screen-sm-max) {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        width: 50%;

        &-right {
          left: 50%;
        }
      }

      &-right {
        right: 1.5rem;
      }

      .icon {
        width: 100%;
        height: 100%;
        color: $slider-arrow-color;
        transition: all 0.2s ease-in-out;

        @media (max-width: $screen-sm-max) {
          position: absolute;
          top: calc(50% - 0.7rem);
          left: calc(50% - 1.2rem);
          width: 2.4rem;
          height: 2.4rem;
          color: $text-dark-light;
        }

        &:hover {
          color: $brand-primary;
        }
      }
    }
  }

  & > .section-heading {
    margin: 0 0 6rem;
    font-weight: $light-font;
    text-align: center;

    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 3rem;
    }
  }

  &__property {
    @media (max-width: $screen-xs-max) {
      padding: 1.5rem;
    }

    @media (width >= 600px) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  .info-section {
    padding: 0;
  }
}
