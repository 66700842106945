.header__banner {
  position: relative;
  padding: 6.5rem 0;

  @media (max-width: $screen-xs-max) {
    padding: 2.1rem 0 2.2rem;
  }

  &[style*="background-image"] {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      content: "";
      background: linear-gradient(
        to right,
        $color-gray 30%,
        rgb(0 0 0 / 0) 100%
      );

      @media (max-width: $screen-xs-max) {
        background: $dark-color;
      }
    }
  }

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 5rem;
    height: 5rem;
    margin-right: 1.5rem;
    color: #9094a9;

    @media (max-width: $screen-xs-max) {
      width: 2.4rem;
      height: 3.4rem;
    }
  }

  &__title {
    font-size: 4rem;
    font-weight: $regular-font;
    color: $color-white;

    @media (max-width: $screen-xs-max) {
      font-size: 2.4rem;
    }
  }
}
