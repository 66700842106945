.preloader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  color: $color-black;

  &.light {
    background: $color-white;
  }

  &.dark {
    background: $background-gray-dark;
  }

  &.transparent {
    background: none;
  }

  &__inner,
  &__inner::before,
  &__inner::after,
  &__inner-secondary {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    color: inherit;
    border: 0.1rem solid transparent;
    border-left-color: currentColor;
    border-radius: 50%;
    transform-origin: center center;
    animation-delay: 700ms;
  }

  &__inner-secondary {
    top: calc(50% - #{$preloader-size / 2});
    left: calc(50% - #{$preloader-size / 2});
    width: $preloader-size;
    height: $preloader-size;
    border-left-color: $logo-green;
    animation: preloader-spin 800ms linear infinite;
  }

  &__inner {
    top: calc(50% - #{(0.9 * $preloader-size) / 2});
    left: calc(50% - #{(0.9 * $preloader-size) / 2});
    width: 0.9 * $preloader-size;
    height: 0.9 * $preloader-size;
    border-left-color: $logo-red;
    animation: preloader-spin 1600ms linear infinite;

    &::before {
      top: calc(50% - #{(0.8 * $preloader-size) / 2});
      left: calc(50% - #{(0.8 * $preloader-size) / 2});
      width: 0.8 * $preloader-size;
      height: 0.8 * $preloader-size;
      content: "";
      border-left-color: $logo-blue;
      animation: preloader-spin 2000ms linear infinite;
    }

    &::after {
      top: calc(50% - #{(0.7 * $preloader-size) / 2});
      left: calc(50% - #{(0.7 * $preloader-size) / 2});
      width: 0.7 * $preloader-size;
      height: 0.7 * $preloader-size;
      content: "";
      border-left-color: $logo-orange;
      animation: preloader-spin 2400ms linear infinite;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.4 * $preloader-size;
    height: 0.4 * $preloader-size;
    margin: 0;
    transform: translate(-50%, -50%);
  }

  @keyframes preloader-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
