@import "../../node_modules/react-day-picker/lib/style";

.DayPickerInput {
  display: block;

  &-OverlayWrapper {
    z-index: 10;

    .DayPicker-Caption {
      margin-bottom: 10px;
    }

    abbr {
      cursor: default;
      border: 0;
    }
  }
}
