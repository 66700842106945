.my-account-notifications {
  &__content {
    position: relative;
    min-height: 46rem;
    padding: 4rem 0;
    font-size: 2rem;

    @media (max-width: $grid-float-breakpoint) {
      padding: 2.5rem 0 0.5rem;
    }
  }
}
