$font-family-base: "Ubuntu", sans-serif;

$brand-primary: #f47c24;
$text-color: #464646;
$text-second-color: #505050;

$color-black: #000;
$color-white: #fff;
$color-gray: #3c3e48;
$color-medium-gray: #8a8a8a;
$color-second-gray: #82848e;
$color-green: #c3fc73;
$color-dark-green: #31891d;
$color-red: #d94848;
$color-yellow: #daa83d;

$logo-blue: #30b9d3;
$logo-green: #bad532;
$logo-orange: #f7941d;
$logo-red: #d13a33;

$text-light: #dadbe4;
$text-dark-light: #9497a0;
$text-dark: #363636;

$border-color: #e0e0e0;
$border-color-dark: #51535d;
$box-shadow-color: #f1f1f1;
$chevron-color: #828282;
$slider-arrow-color: #ccc;

$dark-color: #454752;
$background-gray: #e4e4e4;
$background-green: #58bf47;
$background-light-green: #fafff3;
$background-light-red: #fff3f3;
$background-gray-medium: #f4f4f4;
$background-gray-dark: #2e303b;
$background-yellow: #fffbef;

$spacing-main: 4.5rem;
$border-radius: 0.4rem;
$preloader-size: 12rem;
$body-font-size: 1.6rem;

$light-font: 300;
$regular-font: 400;
$medium-font: 500;

$btn-secondary: #595b66;

$label-color: $text-color;
$input-border-radius: $border-radius;
$input-border-focus: $brand-primary;
$input-border: $border-color;
$input-height-base: 5.4rem;
$form-group-margin-bottom: 0;

$body-bg: $color-white;
$font-size-h1: 3.6rem;
$font-size-h2: 3rem;
$font-size-h3: 2.4rem;
$font-size-h4: 1.8rem;
$font-size-h5: 1.4rem;
$font-size-h6: 1.2rem;

$mobile-menu-width: 25rem;

$grid-float-breakpoint: 1024px;
$screen-sm: 769px;
$screen-landscape: 667px;
$screen-mobile: 375px;

@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
