.properties-filters {
  @media (width <= 769px) {
    height: auto;
    max-height: 700rem;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &.collapse {
      max-height: 0;
    }
  }

  .how-it-works-button {
    @media (width <= 769px) {
      display: none;
    }
  }

  .clear-btn {
    font-size: 1.5rem;
    color: $text-dark-light;
    border-bottom: 0.1rem solid transparent;

    &:not(.inline) {
      margin: 0 0 2.4rem;
    }

    .clear-icon {
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      margin: 0 0 0 0.5rem;
      color: inherit;
      vertical-align: baseline;
    }

    &:hover {
      border-bottom: 0.1rem solid currentColor;
    }
  }

  &__form {
    margin-bottom: 4rem;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 2.5rem;
    }

    .row.no-gutters {
      margin-right: 0;
      margin-left: 0;

      & > [class^="col-"],
      & > [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
      }

      & > [class^="col-"]:not(:last-of-type) {
        .dropdown,
        .form-control {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      & > [class^="col-"]:not(:first-of-type) {
        .dropdown,
        .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    .form-group {
      padding-bottom: 0.5rem;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 1.3rem;

        @media (max-width: $screen-sm-max) {
          margin-bottom: 1rem;
        }
      }

      @media (max-width: $screen-sm-max) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: $screen-xs-max) {
        margin-bottom: 1rem;
      }

      &:last-of-type {
        margin-bottom: 2rem;

        @media (max-width: $screen-xs-max) {
          margin-bottom: 1.5rem;
        }
      }

      .form-label,
      label {
        margin-bottom: 0;
      }
    }

    .dropdown {
      &__value {
        padding: 0 1.5rem 0 2rem;

        &-label {
          width: calc(100% - 2.8rem);
          margin-right: 1.5rem;
        }
      }

      &__option {
        .label {
          width: calc(100% - 2.5rem);
        }

        .icon {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
