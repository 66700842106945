.privacy-policy {
  padding-bottom: 4rem;

  .text-content {
    @media (max-width: $screen-sm-max) {
      padding-top: 25px;
    }

    h2 {
      margin-bottom: 0;
    }
  }
}
