.tooltip {
  position: relative;
  bottom: 0.5rem;
  display: inline-block;
  margin-left: 0.5rem;
  color: $brand-primary;
  vertical-align: middle;
  background: none;
  border: 0 none;

  .icon {
    .svg-container {
      width: 2rem;
      height: 2rem;
    }
  }

  .text-content {
    p {
      margin-bottom: 0;
    }
  }

  .react-tooltip {
    width: 30rem;
    max-width: calc(100vw - 2rem);
    font-size: 13px;
  }

  &--orange {
    .react-tooltip {
      background-color: $logo-orange;

      &.react-tooltip__place-right::after {
        border-right-color: $logo-orange;
      }

      &.react-tooltip__place-left::after {
        border-left-color: $logo-orange;
      }

      &.react-tooltip__place-top::after {
        border-top-color: $logo-orange;
      }

      &.place-top::after {
        border-bottom-color: $logo-orange;
      }
    }
  }
}
