.gallery-upload {
  &__input {
    opacity: 1;
  }

  &__list {
    overflow: hidden;
    list-style: none;
  }
}

.sortable-item {
  list-style: none;

  &:nth-of-type(7n + 0) {
    clear: both;
  }

  &__image {
    width: 100%;
  }

  .preview-name {
    word-break: break-all;
  }
}
