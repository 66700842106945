.footer-pre {
  position: relative;
  z-index: -1;
  padding: 3.7rem 0;
  background: #f2f2f2;
  border-top: 0.1rem solid #e2e2e2;

  @media (max-width: $screen-xs-max) {
    padding: 0;
  }

  &::before {
    position: absolute;
    top: -0.2rem;
    left: 0;
    width: 100%;
    height: 0.2rem;
    content: "";
    box-shadow: inset 0 0.2rem 0.2rem 0 $box-shadow-color;
  }

  @media (min-width: $screen-sm-min) {
    .container .row {
      display: flex;
      align-items: center;
    }
  }

  &__payments {
    @media (max-width: $screen-xs-max) {
      padding: 1.5rem 2.5rem;
      border-bottom: 0.1rem solid $background-gray;

      img {
        margin: 0 auto;
      }
    }
  }

  &__integrations {
    @media (max-width: $screen-xs-max) {
      padding: 2rem 0;
    }

    ul {
      padding: 0;
      margin: 0;
      font-size: 0;
      list-style: none;

      @media (max-width: $screen-xs-max) {
        text-align: center;
      }

      li {
        display: inline-block;

        &:not(:last-of-type) {
          margin-right: 3.5rem;
        }

        a {
          position: relative;
          display: inline-block;
          width: 3.6rem;
          height: 3.6rem;
          border-radius: 50%;
        }

        .svg-container {
          z-index: 2;
          width: auto;
          height: auto;
          color: $color-white;
        }

        .icon__tsi {
          width: 10.5rem;
          height: 5.5rem;
        }

        .icon__tpo {
          width: 13rem;
          height: 4.5rem;
        }
      }
    }
  }
}

.footer-main {
  padding: 3.5rem 0;
  color: $text-dark-light;
  background: $color-gray;

  @media (max-width: $screen-xs-max) {
    padding: 0;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    padding: 0 0 3rem;
    margin: 0 0 3.5rem;
    list-style: none;
    border-bottom: 0.1rem solid $border-color-dark;

    @media (max-width: $screen-lg-min) {
      display: block;
      text-align: center;
    }

    @media (max-width: $screen-xs-max) {
      display: none;
    }

    li {
      position: relative;
      display: inline-block;
      margin-bottom: 0.5rem;
      line-height: 1.4;

      &:not(:last-of-type) {
        padding: 0 1.5rem 0 0;
        margin-right: 1.5rem;

        @media (max-width: $screen-md-max) and (min-width: $screen-md) {
          margin-right: 1rem;
        }

        &::after {
          position: absolute;
          top: calc(50% - 0.2rem);
          right: -0.2rem;
          display: block;
          width: 0.4rem;
          height: 0.4rem;
          content: "";
          background: $text-dark-light;
          border-radius: 50%;
        }
      }

      a {
        font-size: 1.9rem;
        color: $text-dark-light;

        @media (max-width: $screen-md-max) and (min-width: $screen-md) {
          font-size: 1.7rem;
        }
      }
    }
  }

  &__company {
    font-size: 1.5rem;
    color: #94969f;

    @media (min-width: $screen-lg-min) {
      & > .row {
        display: flex;
        justify-content: space-between;

        div[class^="col-"] {
          flex: auto;
          width: auto;
        }
      }
    }

    p {
      margin-bottom: 0.5rem;
      line-height: 1.4;
    }

    a,
    .text-light {
      color: $text-light;
    }

    &--contact {
      @media (max-width: $screen-xs-max) {
        padding: 1.5rem 0 1rem;
      }

      a,
      .svg-container {
        vertical-align: middle;
      }

      a {
        @media (min-width: $screen-sm-min) {
          display: inline-block;
          max-width: calc(100% - 3rem);
          word-wrap: break-word;
        }
      }

      .svg-container {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 1rem;
        color: $text-light;
      }
    }

    &--info {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-bottom: 2.5rem;
      }

      @media (max-width: $screen-xs-max) {
        padding: 2rem 0 1.5rem;
        text-align: center;
        border-bottom: 0.1rem solid #5c5e68;
      }
    }

    &--copy {
      @media (max-width: $screen-xs-max) {
        padding-bottom: 1.5rem;
      }
    }

    &--contact,
    &--copy {
      @media (max-width: $screen-xs-max) {
        text-align: center;
      }

      @media (width <= 425px) {
        padding-right: 2.5rem;
        padding-left: 2.5rem;
      }
    }
  }
}
