.mobile-menu__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.layout.menu-open {
  .mobile-menu__overlay {
    z-index: 999;
    opacity: 0.5;
  }
}
