.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
  display: none;
  width: $mobile-menu-width;
  height: 100vh;
  padding: 0 0 6rem;
  overflow: hidden auto;
  background: $color-gray;

  @media (max-width: $grid-float-breakpoint) {
    display: block;
  }

  &__account {
    width: 100%;
    color: $text-dark-light;
    border-bottom: 0.1rem solid $border-color-dark;

    a {
      color: inherit;

      &:focus {
        box-shadow: none !important;
      }
    }

    .sign-in,
    .register {
      display: inline-block;
      width: 50%;
      padding: 2.5rem 1rem 2.3rem;
      text-align: center;
      vertical-align: middle;
      background: $background-gray-dark;

      &:only-child {
        width: 100%;
      }

      &:not(:last-child) {
        border-right: 0.1rem solid rgba($border-color-dark, 0.7);
      }
    }
  }

  &__menu {
    padding: 0;
    margin: 0;
    list-style: none;

    .menu-item {
      width: 100%;
      padding: 0;

      &.has-submenu {
        button {
          position: relative;
          padding-right: 3rem;
        }

        .icon {
          position: absolute;
          top: calc(50% - 0.3rem);
          right: 1rem;
          width: 1.4rem;
          height: 0.6rem;
          color: $color-white;
          transition: all 0.3s ease;
          transform: rotate(0deg);
        }

        &.open {
          .icon {
            transform: rotate(180deg);
          }

          .menu-item__sub {
            display: block;
          }
        }

        .menu-item__sub {
          display: none;
          width: 100%;
          padding: 0;
          margin: 0;
          list-style: none;

          .sub-menu-item {
            display: block;
            color: $text-dark-light;
            background: $background-gray-dark;

            a {
              border-bottom: 0.1rem solid $border-color-dark;
            }
          }
        }
      }

      a,
      button {
        display: block;
        width: 100%;
        padding: 1.8rem 1.8rem 1.6rem;
        color: $color-white;
        text-align: left;
        background: none;
        border: 0.1rem solid $border-color-dark;
        border-width: 0 0 0.1rem;

        &:hover {
          text-decoration: none;
        }

        &:focus,
        &:active {
          outline: 0 none;
          box-shadow: none !important;
        }
      }
    }
  }
}
