.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  transition: background 0.5s ease;
  transform: translate3d(0, 0, 0);

  &.opening {
    z-index: 9999;
    background: rgba($color-black, 0);

    .modal__frame {
      transform: translate(0, 150vh);
    }
  }

  &.open {
    z-index: 9999;
    background: rgba($color-black, 0.55);

    .modal__frame {
      transform: translate(0, 0);
    }
  }

  &.hiding {
    z-index: 9999;
    background: rgba($color-black, 0);

    .modal__frame {
      transform: translate(0, 150vh);
    }
  }

  &.hidden {
    z-index: -1;
    background: rgba($color-black, 0);

    .modal__frame {
      transform: translate(0, 150vh);
    }
  }

  &__frame {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 92%;
    max-width: 68rem;
    padding: 0 0 2.4rem;
    margin: 9rem auto 2rem;
    font-size: 1.7rem;
    cursor: default;
    background: $color-white;
    border-radius: $border-radius;
    transition: transform 0.5s ease;
    transform: translate(0, 150vh);

    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 6rem;
    }
  }

  &__header {
    position: relative;
    padding: 8rem 2.4rem 2.4rem;
    font-size: 0;
    line-height: 0;

    @media (max-width: $grid-float-breakpoint-max) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    .title {
      display: inline-block;
      width: 100%;
      margin: 0;
      font-size: 2.8rem;
      font-weight: $medium-font;
      line-height: 1;
      color: $text-second-color;
      text-align: center;
      vertical-align: middle;
    }

    .close-button {
      position: absolute;
      top: 2.4rem;
      right: 2.4rem;
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0;
      font-size: 0;
      color: $text-dark-light;
      vertical-align: middle;
      background: none;
      border: 0 none;
      outline: 0 none !important;
      box-shadow: none !important;
      transition: all 0.3s linear;

      .icon {
        width: 100%;
        height: 100%;
        color: inherit;
      }
    }

    .icon-container {
      position: absolute;
      top: -4.5rem;
      left: calc(50% - 4.5rem);
      width: 9rem;
      height: 9rem;
      background: $brand-primary;
      border-radius: 50%;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4rem;
        height: 4rem;
        color: $color-white;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__body {
    padding: 0 4rem 3.5rem;

    p {
      line-height: 1.4;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0 2rem 1.5rem;
    }
  }
}
