.property-open-days-modal {
  .subtitle {
    margin: 0 0 2rem;
  }

  .modal__body {
    position: relative;
    min-height: 12rem;
    overflow: hidden;
  }

  .book-btn {
    width: 100%;

    .icon {
      position: relative;
      top: -0.1rem;
      width: 1.8rem;
      height: 1.2rem;
      margin-left: 1rem;
    }
  }

  .description {
    margin: 0 0 2rem;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    .manage-open-days-list {
      margin: 3rem 0 0;
    }
  }
}
