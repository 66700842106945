.property-tabs {
  &__menu {
    display: block;
    width: 100%;
  }

  &__menu-item {
    display: inline-block;
    padding: 2rem;
    margin: 0 0.3rem 0 0;
    font-size: 1.9rem;
    font-weight: $medium-font;
    color: $text-color;
    text-decoration: none !important;
    vertical-align: baseline;
    background: $background-gray;
    background-clip: padding-box;
    border-top: 0.2rem solid transparent;
    transition: all 0.3s ease;

    @media (max-width: $screen-sm-max) {
      padding: 2rem 1.2rem;
    }

    @media (max-width: $screen-xs-max) {
      padding: 2rem 2.5rem;
    }

    &:last-child {
      margin: 0;
    }

    &.active {
      color: $color-black;
      background: $color-white;
      border-color: $brand-primary;

      .icon {
        color: $brand-primary;
      }
    }

    @media (min-width: $grid-float-breakpoint) {
      &:not(.active) {
        &:hover,
        &:focus {
          color: $color-black;
          background: $color-white;
          background-clip: padding-box;

          .icon {
            color: $brand-primary;
          }
        }
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      &:focus,
      &:hover {
        color: inherit;
      }
    }

    &:focus {
      box-shadow: none;
    }

    .icon {
      width: 2rem;
      height: 2rem;
      margin: 0 1.5rem 0 0;
      color: inherit;

      @media (max-width: $screen-sm-max) {
        margin: 0 1rem 0 0;
      }

      @media (max-width: $screen-xs-max) {
        margin: 0 1.5rem 0 0;
      }
    }
  }

  &__content {
    padding: 3.5rem;
    background: $color-white;
  }

  &__description {
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 2.5rem;
    }

    p {
      margin-bottom: 2rem;
      line-height: 1.5;
    }

    strong,
    b {
      font-weight: $medium-font;
    }
  }
}
