.map {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 0 50%;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
