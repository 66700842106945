button {
  padding: 0;
  background: none;
  border: none;
  outline: none;
}

.btn-group {
  .btn:not(:last-child) {
    margin-right: 1rem;
  }
}

.btn {
  display: inline-block;
  padding: 1.8rem 2.2rem;
  font-size: 1.7rem;
  font-weight: $medium-font;
  border: 0;
  border-radius: $border-radius;
  transition: all 0.3s ease;

  @media (width <= 375px) {
    padding: 1.8rem 1.5rem;
    font-size: 1.6rem;
  }

  @media (width <= 325px) {
    font-size: 1.5rem;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.btn-primary {
    color: $color-white;
    background: $brand-primary;

    &:hover,
    &:focus {
      background: darken($brand-primary, 15);
    }

    &:focus {
      @include outer-glow($brand-primary);
    }
  }

  &.btn-primary--outline {
    color: $brand-primary;
    background: transparent;
    border: 0.2rem solid $brand-primary;

    .arrow {
      color: $brand-primary;
    }

    &:hover,
    &:focus {
      color: $color-white;
      background: $brand-primary;

      .arrow {
        color: $color-white;
      }
    }

    &:focus {
      @include outer-glow($brand-primary);
    }
  }

  &.btn-secondary {
    color: $color-white;
    background: $btn-secondary;

    &:hover,
    &:focus {
      background: darken($btn-secondary, 15);
    }

    &:focus {
      @include outer-glow($btn-secondary);
    }
  }

  &.btn-green {
    color: $color-white;
    background: $background-green;

    &:hover,
    &:focus {
      background: darken($background-green, 15);
    }

    &:focus {
      @include outer-glow($background-green);
    }
  }

  &.btn-green--outline {
    color: $background-green;
    background: transparent;
    border: 0.2rem solid $background-green;

    .arrow {
      color: $background-green;
    }

    &:hover,
    &:focus {
      color: $color-white;
      background: $background-green;

      .arrow {
        color: $color-white;
      }
    }

    &:focus {
      @include outer-glow($background-green);
    }
  }

  &.btn-white {
    color: $brand-primary;
    background: $color-white;

    .svg-container {
      width: 1.3rem;
      height: 1.3rem;
      color: inherit;
    }
  }

  &.btn-red {
    color: $color-white;
    background: $color-red;

    &:hover,
    &:focus {
      background: darken($color-red, 15);
    }
  }

  &.btn-red--outline {
    color: $color-red;
    background: transparent;
    border: 0.2rem solid $color-red;

    .arrow {
      color: $color-red;
    }

    &:hover,
    &:focus {
      color: $color-white;
      background: $color-red;

      .arrow {
        color: $color-white;
      }
    }

    &:focus {
      @include outer-glow($color-red);
    }
  }

  &.btn-icon {
    &--right {
      .svg-container {
        width: 2rem;
        height: 2rem;
        margin-left: 1.5rem;
      }
    }
  }

  &.btn-block {
    display: block;
    width: 100%;
    text-align: center;
  }

  /*
   * Add this class if button has right arrow icon
   * and should have hover transition
   */
  &.with-arrow {
    &:hover,
    &:focus {
      .arrow {
        transform: translate(0.5rem, 0);
      }
    }

    .arrow {
      margin-left: 1rem;
    }

    &--reversed {
      &:hover,
      &:focus {
        .arrow {
          transform: translate(-0.5rem, 0);
        }
      }

      .arrow {
        margin-right: 1rem;
      }
    }
  }

  .arrow {
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    vertical-align: baseline;
    transition: all 0.3s ease;
  }

  &.btn-lg {
    padding: 2.2rem 3.3rem;
    font-size: 1.9rem;
  }

  &.btn-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
