@media (max-width: $screen-xs-max) {
  .property-tabs {
    padding: 0;
    border-bottom: 0.1rem solid $border-color;

    .property-tabs__menu-item {
      width: 100%;
      background-color: $background-gray-medium;
      border-top: 0.1rem solid $border-color;

      &.active {
        background-color: $color-white;
        border-top: 0.2rem solid $brand-primary;
        border-bottom: 0.1rem solid $border-color;
      }
    }

    .Tabs__Content {
      padding: 2rem 2.5rem;
      overflow: hidden;
      background: $color-white;
    }
  }
}
