.property {
  &__content {
    @media (max-width: $screen-sm-max) {
      padding-top: 2rem;
    }
  }

  &__title {
    margin: 0 0 1.2rem;
    font-size: 2.8rem;
    font-weight: $medium-font;

    .icon {
      display: inline-block;
      width: 1.4rem;
      height: 0.6rem;
      margin: 0 0 0 2rem;
      color: $brand-primary;
      vertical-align: middle;

      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }
  }

  &__basic-data {
    margin: 0 0 3rem;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 2rem;
    }
  }

  &__details {
    padding: 3.5rem 1.5rem 4rem;
    background: $background-gray-medium;
    box-shadow: inset 0 0.1rem 0.2rem 0 $background-gray;

    @media (max-width: $screen-xs-max) {
      padding: 0;
    }
  }

  &__top-section {
    padding-bottom: 3.5rem;

    @media (max-width: $screen-xs-max) {
      padding-bottom: 2.5rem;
    }

    .anti-snipping {
      margin: 0 0 1rem;
      font-size: 2rem;
      color: $color-red;
    }

    .property-end-timer {
      padding: 0;
      margin: 0 0 1.5rem;
      border: 0 none;
    }

    .bidding-window-info {
      margin: 0 0 1.5rem;
    }

    .bidding-box {
      border-top: 0.1rem solid $border-color;
    }
  }

  &__open-days {
    padding: 4rem 0;
    border-top: 0.1rem solid $border-color;

    @media (max-width: $screen-xs-max) {
      padding: 2rem 2.5rem 0;
      margin: 0 -2.5rem;
    }

    .help-buttons {
      @media (max-width: $screen-xs-max) {
        padding: 0;
      }

      @media (max-width: $screen-xs) {
        display: flex;
      }

      .btn {
        width: 100%;
        font-weight: $regular-font;
        color: $color-white;
        background: $background-green;
        border-color: $border-color;
        border-style: solid;
        border-width: 0.1rem 0.1rem 0;
        transition: all 0.3s ease;

        @media (max-width: $screen-xs-max) {
          width: 50%;
          border: 0.1rem solid $border-color;
        }

        @media (max-width: $screen-xs) {
          font-size: 1.5rem;
        }

        span {
          vertical-align: middle;
        }

        &:first-of-type {
          background: $logo-blue;

          &:not(:last-of-type) {
            border-radius: $border-radius $border-radius 0 0;

            @media (max-width: $screen-xs-max) {
              padding-right: 1rem;
              padding-left: 2.5rem;
              border-right: none;
              border-left: none;
              border-radius: 0;
            }
          }
        }

        &:last-of-type {
          border-width: 0.1rem;

          &:not(:first-of-type) {
            border-radius: 0 0 $border-radius $border-radius;

            @media (max-width: $screen-xs-max) {
              padding-right: 2.5rem;
              padding-left: 1.5rem;
              border-right: none;
              border-radius: 0;
            }
          }
        }

        &:not(:first-of-type) {
          &:not(:last-of-type) {
            border-radius: 0;
          }
        }

        &:hover {
          color: $text-dark-light;
          background: $background-gray-medium;
          border-color: $border-color;

          .icon {
            color: $text-dark-light;
          }
        }
      }

      .icon {
        width: 2.2rem;
        height: 2.8rem;
        margin: 0 1.8rem 0 0;
        color: $color-white;
        transition: color 0.3s ease;

        @media (max-width: $screen-xs) {
          margin: 0 0.6rem 0 0;
        }
      }
    }
  }
}

.property-special-conditions {
  padding: 3rem 0;
  border-top: 0.1rem solid $border-color;

  @media (max-width: $screen-xs-max) {
    padding: 2rem 0;
    border-top: 0;
  }

  .section-heading {
    margin-bottom: 2rem;
    font-size: 2.2rem;
  }
}
