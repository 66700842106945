.won-property__content {
  .steps {
    padding: 4rem 0;
    text-align: center;
    background: $background-gray-medium;
    box-shadow: inset 0 0.1rem 0.2rem 0 $background-gray;

    @media (max-width: $screen-xs-max) {
      padding: 2.5rem 0 2rem;
    }

    .all-steps {
      max-width: 86rem;
      margin: 0 auto;
    }

    .step {
      position: relative;
      display: inline-block;
      width: 33.333%;

      &::after {
        position: absolute;
        top: 5rem;
        left: 50%;
        width: 100%;
        height: 0.2rem;
        content: "";
        background: $slider-arrow-color;

        @media (max-width: $screen-xs-max) {
          content: none;
        }
      }

      &:last-child {
        &::after {
          content: none;
        }
      }

      &.step-active {
        .circle {
          border: 0.2rem solid $brand-primary;

          &::after {
            position: absolute;
            inset: 0.2rem 0 0 0.2rem;
            width: calc(100% - 0.4rem);
            height: calc(100% - 0.4rem);
            content: "";
            background: $brand-primary;
            border-radius: 50%;
          }

          .icon {
            color: $color-white;
          }
        }

        .step {
          &__name {
            font-weight: $medium-font;
            color: $brand-primary;
          }
        }
      }

      &:not(.step-active) {
        @media (max-width: $screen-xs-max) {
          display: none;
        }
      }

      .circle {
        position: relative;
        z-index: 1;
        width: 9.4rem;
        height: 9.4rem;
        padding: 2.3rem 0;
        margin: 0 auto 1.8rem;
        text-align: center;
        background: $background-gray-medium;
        border: 0.2rem solid $slider-arrow-color;
        border-radius: 50%;

        @media (max-width: $screen-xs-max) {
          width: 6.2rem;
          height: 6.2rem;
          padding: 1.4rem 0;
        }

        .icon {
          position: relative;
          z-index: 5;
          width: 4.5rem;
          height: 4.5rem;
          color: $slider-arrow-color;

          @media (max-width: $screen-xs-max) {
            width: 3rem;
            height: 3rem;
          }
        }
      }

      &__name {
        font-size: 1.9rem;
        color: $text-dark-light;

        @media (max-width: $screen-xs-max) {
          font-size: 1.8rem;
        }
      }
    }
  }

  .container-property {
    position: relative;

    & > .preloader {
      z-index: 90;
    }

    @media (max-width: $screen-xs-max) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (max-width: $screen-xs-max) {
    &--container {
      padding: 0;
    }
  }

  &--summary {
    padding: 3.5rem 0;
    border-bottom: 0.1rem solid $background-gray;

    @media (max-width: $screen-xs-max) {
      padding: 3rem 2.5rem 2.5rem;
    }

    .icon {
      width: 6.2rem;
      height: 6.2rem;
      margin-top: 1rem;
      color: $color-dark-green;
      text-align: center;
      vertical-align: top;
      border: 0.3rem solid $color-dark-green;
      border-radius: 50%;

      @media (max-width: $screen-xs-max) {
        display: block;
        margin: 0 auto;
      }

      svg {
        width: 3rem;
        height: 2rem;
        margin: 1.7rem 0;
      }
    }

    .text {
      display: inline-block;
      padding-left: 2.5rem;
      vertical-align: middle;

      @media (max-width: $screen-xs-max) {
        display: block;
        padding-left: 0;
        margin-top: 2rem;
        text-align: center;
      }

      p {
        font-size: 2.8rem;
        line-height: 1.5;

        @media (max-width: $screen-xs-max) {
          font-size: 2.4rem;
          line-height: 1.375;
        }
      }

      .price {
        font-weight: $medium-font;
      }
    }
  }

  &--details {
    padding: 3.5rem 0;
    border-bottom: 0.1rem solid $background-gray;

    @media (max-width: $screen-xs-max) {
      padding: 2rem 2.5rem;
    }

    .text-content {
      p {
        font-size: 1.7rem;

        @media (max-width: $screen-xs-max) {
          font-size: 1.6rem;
        }
      }
    }
  }

  &--proceed {
    padding: 3.5rem 0 4rem;

    @media (max-width: $screen-xs-max) {
      padding: 2rem 2.5rem 2.5rem;
    }

    .col-info {
      @media (max-width: $screen-sm-max) {
        margin-bottom: 3rem;
      }

      @media (max-width: $screen-xs-max) {
        padding-bottom: 2rem;
        margin-bottom: 2.2rem;
        border-bottom: 0.1rem solid $background-gray;
      }
    }

    .text-content {
      p {
        font-size: 1.7rem;

        @media (max-width: $screen-xs-max) {
          font-size: 1.6rem;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }

    .table-title {
      margin-bottom: 2rem;
      font-size: 2.5rem;
      font-weight: 500;
      text-decoration: underline;
    }

    .prices {
      display: inline-block;
      margin-bottom: 3.5rem;

      @media (max-width: $screen-xs-max) {
        width: 100%;
        margin-bottom: 2rem;
      }

      .field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1.5rem;
        overflow: hidden;

        @media (max-width: $screen-xs-max) {
          margin-bottom: 0.8rem;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .label,
        .value {
          display: inline-block;
          font-size: 2.2rem;
          vertical-align: middle;

          @media (max-width: $screen-xs-max) {
            font-size: 1.6rem;
          }
        }

        .label {
          max-width: 34rem;
          font-weight: $medium-font;
        }

        @media (min-width: $screen-xs) {
          .value {
            padding-left: 2.5rem;
          }
        }
      }
    }

    .action {
      .btn {
        @media (max-width: $screen-mobile) {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  &--confirmation {
    padding: 3.5rem 0 4rem;
    text-align: center;

    @media (max-width: $screen-xs-max) {
      padding: 3rem 2.5rem 2.5rem;
    }

    .icon {
      width: 12rem;
      height: 12rem;
      margin-bottom: 3.5rem;
      color: $brand-primary;

      @media (max-width: $screen-xs-max) {
        width: 7.6rem;
        height: 7.6rem;
        margin-bottom: 2rem;
      }
    }

    .title {
      margin-bottom: 2.5rem;
      font-size: 2.8rem;
      font-weight: $medium-font;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 1.3rem;
        font-size: 2.4rem;
      }
    }

    .text-content {
      max-width: 65rem;
      margin: 0 auto 3.5rem;

      @media (max-width: $screen-xs-max) {
        margin: 0 auto 2rem;
      }

      p {
        font-size: 1.7rem;

        @media (max-width: $screen-xs-max) {
          font-size: 1.6rem;
        }
      }
    }

    .btn {
      @media (max-width: $screen-mobile) {
        width: 100%;
        text-align: center;
      }

      .arrow {
        margin-left: 1rem;
      }
    }
  }

  .register-form {
    &__content {
      .container {
        padding: 0;
      }

      h2 {
        margin-bottom: 2.5rem;

        @media (max-width: $screen-xs-max) {
          margin-bottom: 1.8rem;
          font-size: 2.4rem;
        }
      }

      p {
        margin-bottom: 2.5rem;
      }

      label,
      .form-label {
        margin-bottom: 1.5rem;

        @media (max-width: $screen-xs-max) {
          margin-bottom: 1rem;
        }
      }

      .col-form {
        &:first-child {
          padding-right: 2rem;

          @media (max-width: $screen-xs-max) {
            padding-right: 2.5rem;
          }
        }

        &:last-child {
          padding-left: 2rem;

          @media (max-width: $screen-xs-max) {
            padding-left: 2.5rem;
          }
        }
      }

      .actions {
        text-align: left;

        @media (max-width: $screen-xs-max) {
          padding-top: 2.1rem;
        }

        .text-content {
          display: inline-block;
          width: calc(100% - 12.3rem);
          padding-right: 2rem;
          vertical-align: middle;

          @media (max-width: $screen-xs-max) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

#paymentForm {
  #token_container-button {
    visibility: hidden;
  }

  #token_container_holder {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    padding: 4rem 0 !important;
    overflow: auto;
    background: rgba($color-black, 0.55);

    &:not([style*="opacity"]) {
      // when closed, doesn't contain opacity style
      display: none;
    }
  }

  #_iframe_overlay {
    position: absolute;
    inset: 4rem auto auto calc(50% - 4rem) !important;
    z-index: 1000;
    width: 41rem !important;
    height: 4rem !important;
    margin: 0 !important;
    color: transparent;
    background: transparent !important;
    transform: translate(calc(-50% + 2rem), 0) !important;
  }

  iframe {
    position: static !important;
    top: auto !important;
    left: auto !important;
    z-index: 99;
    display: block;
    width: 45rem !important;
    margin: 0 auto !important;
  }

  #_iframe_close {
    position: absolute;
    top: 4rem !important;
    left: calc(50% + 20.5rem) !important;
    z-index: 1000;
    width: 4rem !important;
    height: 4rem !important;
    margin: 0 !important;
    font-size: 0 !important;
    color: transparent;
    transform: translate(-50%, 0);

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10001;
      width: 4rem;
      height: 4rem;
      font-size: 0;
      color: transparent;
      content: "";
      background-image: url("/static/vectors/close-icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 2.5rem 2.5rem;
    }
  }
}
