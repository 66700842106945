.slider {
  overflow: hidden;

  /* Content below copied from from node_modules/slick-carousel/slick/slick; */

  /* Slider */

  .slick-slider {
    position: relative;
    box-sizing: border-box;
    display: block;
    touch-action: pan-y;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;

    &::before,
    &::after {
      display: table;
      content: "";
    }

    &::after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 0.1rem;

    [dir="rtl"] & {
      float: right;
    }

    img {
      display: block;
    }

    &.slick-loading img {
      display: none;
    }

    &.dragging img {
      pointer-events: none;
    }
  }

  .slick-initialized {
    .slick-slide {
      display: block;
    }
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  &.center {
    .slick-initialized {
      .slick-track {
        max-width: 100% !important;
        font-size: 0;
        text-align: center;
      }

      .slick-slide {
        display: inline-block;
        float: none;
        vertical-align: top;
      }
    }
  }

  @import "../../node_modules/slick-carousel/slick/slick-theme";
}
