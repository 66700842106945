.property-search {
  &-bar {
    display: block;
    max-width: 80%;
    margin: 0 auto;
    font-size: 0;
    box-shadow: 0.2rem 0.2rem 0.1rem 0 rgb(0 0 0 / 0.2);

    @media (max-width: $grid-float-breakpoint) {
      max-width: 68rem;
      margin: 0 auto;
    }

    @media (max-width: $screen-xs-max) {
      max-width: 100%;
    }

    &__location {
      display: inline-block;
      width: 60%;
      font-size: 1.9rem;
      vertical-align: top;
      border-right: 0.1rem solid $border-color;
      border-radius: $border-radius 0 0 $border-radius;

      @media (width <= 1024px) {
        width: 100%;
        font-size: 1.6rem;
        border-right: 0 none;
        border-bottom: 0.1rem solid $border-color;
        border-radius: $border-radius $border-radius 0 0;
      }

      .location-input__input {
        height: 7.2rem;
        padding: 1rem 3rem;
        border: 0 none;
        border-radius: $border-radius;

        @media (max-width: $grid-float-breakpoint) {
          height: 5.1rem;
          padding: 1rem 2rem;
        }
      }
    }

    &__radius {
      display: inline-block;
      width: 23%;
      vertical-align: top;
      border: 0;
      border-radius: 0;

      @media (max-width: $grid-float-breakpoint) {
        width: 100%;
        font-size: 1.6rem;
      }

      .dropdown__value {
        height: 7.2rem;
        line-height: 7.2rem;

        @media (max-width: $grid-float-breakpoint) {
          height: 5.1rem;
          padding: 0 2rem;
          line-height: 5.1rem;
        }

        &-label {
          color: $text-color;
        }

        &-icon {
          width: 1.4rem;
          height: 1.4rem;

          svg {
            fill: $chevron-color;
          }
        }
      }
    }

    &__submit {
      width: 17%;
      height: 7.2rem;
      font-size: 0;
      color: $color-white;
      cursor: pointer;
      background: $brand-primary;
      border: 0 none;
      border-radius: 0 $border-radius $border-radius 0;
      outline: 0 none;
      box-shadow: none;

      @media (max-width: $grid-float-breakpoint) {
        width: 100%;
        height: 5.1rem;
        border-radius: 0 0 $border-radius $border-radius;
      }

      .label {
        display: inline-block;
        font-size: 1.9rem;
        font-weight: $medium-font;
        vertical-align: middle;
      }

      .icon {
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        margin: 0 1.5rem 0 0;
        vertical-align: middle;
      }
    }
  }

  p {
    display: block;
    max-width: 80%;
    padding: 0.5rem 0;
    margin: 0 auto;
    font-size: 1.9rem;
    color: white;
  }
}
