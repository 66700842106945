.testimonials {
  &__content {
    @media (max-width: $screen-sm-max) {
      padding-top: 3rem;
    }

    @media (max-width: $screen-sm-max) {
      padding-top: 2rem;
    }
  }

  &__box {
    margin-bottom: 3rem;
    border: 0.2rem solid $border-color;

    @media (max-width: $screen-xs-max) {
      padding: 2rem 2.5rem 0;
      margin: 0 -2.5rem;
      border: none;
      border-top: 0.1rem solid $background-gray;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }
    }

    &__header {
      padding: 4rem;

      @media (max-width: $screen-xs-max) {
        padding: 0;
      }

      &__top {
        display: flex;
        align-items: center;
        margin-bottom: 3rem;

        @media (max-width: $screen-xs-max) {
          margin-bottom: 2.3rem;
        }

        .title {
          flex: 1;
          font-size: 2.8rem;
          font-weight: $medium-font;
          color: $text-second-color;

          @media (max-width: $screen-xs-max) {
            font-size: 2.4rem;
          }
        }

        .toggle {
          font-weight: $medium-font;
          color: $brand-primary;

          .icon {
            width: 1.1rem;
            height: 1.1rem;
            margin-left: 0.5rem;
            vertical-align: baseline;
          }
        }
      }

      &__bottom {
        @media (min-width: $screen-md-min) {
          display: flex;
          align-items: center;
        }

        .attributes {
          display: flex;
          flex: 1;

          @media (max-width: $screen-sm-max) {
            justify-content: center;
          }

          @media (max-width: $screen-xs-max) {
            margin-bottom: 2rem;
            text-align: center;
          }
        }
      }
    }

    &__content {
      padding: 4rem;
      border-top: 0.2rem solid $border-color;

      @media (max-width: $screen-xs-max) {
        padding: 0 0 2.2rem;
        border: none;
      }

      .image {
        max-width: 33rem;
        margin-right: 3rem;

        @media (max-width: $screen-xs-max) {
          max-width: 100%;
          margin: 0 0 3rem;
        }
      }

      @media (min-width: $screen-sm-min) {
        .image,
        .text {
          float: left;
        }
      }
    }

    .attribute {
      display: flex;
      align-items: center;

      &:not(.author) {
        margin-right: 3rem;

        @media (max-width: $screen-xs-max) {
          display: inline-block;
          padding: 0 2rem;
          margin-right: 0;
        }

        .icon {
          margin-right: 1.5rem;

          @media (max-width: $screen-xs-max) {
            margin-right: 0;
            margin-bottom: 1.7rem;
          }
        }
      }

      p {
        span {
          display: block;
          color: $text-second-color;

          &:nth-child(1) {
            font-size: 1.7rem;

            @media (max-width: $screen-xs-max) {
              font-size: 1.5rem;
            }
          }

          &:nth-child(2) {
            margin-top: 0.5rem;
            font-size: 1.9rem;
            font-weight: $medium-font;
          }
        }
      }

      .icon {
        width: 4.2rem;
        height: 4.2rem;
      }

      &.sold .icon {
        color: $logo-blue;
      }

      &.reserve .icon {
        color: $brand-primary;
      }

      &.bids .icon {
        color: $logo-red;
      }

      &.author {
        margin: 0;
        text-align: right;

        @media (max-width: $screen-sm-max) {
          justify-content: center;
          margin-top: 2.5rem;
        }

        @media (max-width: $screen-xs-max) {
          display: flex;
          flex-direction: row;
          flex-flow: column;
          justify-content: normal;
          padding: 2.2rem 2.5rem 2.5rem;
          margin: 0 -2.5rem;
          text-align: left;
          border-top: 0.1rem solid $background-gray;

          > p {
            order: 2;
          }

          .icon {
            order: 1;
            margin-right: 1.7rem;
          }
        }

        p {
          span {
            &:nth-child(1) {
              font-size: 1.9rem;
              font-weight: $medium-font;
            }

            &:nth-child(2) {
              margin-top: 0.8rem;
              font-size: 1.7rem;
              font-weight: $regular-font;
            }
          }
        }

        .icon {
          margin-left: 1.5rem;
          color: $logo-green;

          @media (max-width: $screen-xs-max) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .how-it-works__content {
    .box {
      .icon {
        vertical-align: middle;
      }

      .content {
        .title {
          margin-bottom: 0;
        }
      }
    }
  }
}
