.my-account-open-days {
  &__content {
    position: relative;
    padding: 4rem 0;

    @media (max-width: $screen-xs-max) {
      padding: 2rem 0 4rem;
    }
  }

  .notification-box {
    margin-bottom: 4rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 2rem;
    line-height: 2;

    a {
      font-weight: $medium-font;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .table-head,
  .table-row {
    display: flex;
    font-size: 1.7rem;

    @media (max-width: $screen-xs-max) {
      display: block;
      font-size: 1.6rem;
    }

    & > div {
      display: flex;
      align-items: center;
      padding: 1.5rem;

      @media (max-width: $screen-xs-max) {
        display: block;
      }

      &:not(:last-child) {
        border-right: 0.1rem solid $border-color;

        @media (max-width: $screen-xs-max) {
          border-right: none;
        }
      }
    }

    & > div:nth-child(1) {
      justify-content: center;
      width: 5%;

      @media (max-width: $screen-xs-max) {
        float: left;
        width: 4.5rem;
        padding: 0;

        &::before {
          display: inline-block;
          content: "#";
        }
      }
    }

    & > div:nth-child(2) {
      width: 47.5%;

      @media (max-width: $screen-sm-max) {
        width: 40%;
      }

      @media (max-width: $screen-xs-max) {
        float: left;
        width: calc(100% - 4.5rem);
        padding: 0 0 0 1rem;
        margin-bottom: 0.3rem;
      }
    }

    & > div:nth-child(3) {
      justify-content: center;
      width: 28.5%;

      @media (max-width: $screen-sm-max) {
        width: 30%;
      }

      @media (max-width: $screen-xs-max) {
        width: 100%;
        padding: 0 0 0 5.5rem;
        clear: both;

        .formatted-date {
          color: $color-medium-gray;
        }
      }
    }

    & > div:nth-child(4) {
      justify-content: center;
      width: 20%;

      @media (max-width: $screen-sm-max) {
        width: 25%;
      }

      @media (max-width: $screen-xs-max) {
        width: 100%;
        padding: 0 0 0 5.5rem;
      }
    }
  }

  .table-head {
    font-weight: $medium-font;
    color: $color-medium-gray;
    background: #f6f6f6;
    border: 0.1rem solid $border-color;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .table-row {
    border-right: 0.1rem solid $border-color;
    border-bottom: 0.1rem solid $border-color;
    border-left: 0.1rem solid $border-color;

    @media (max-width: $screen-xs-max) {
      padding: 1.5rem 2.5rem;
      margin: 0 -2.5rem;
      border-right: none;
      border-left: none;

      &:last-child {
        border-bottom: none;
      }
    }

    a,
    button {
      font-weight: $medium-font;
    }
  }

  .action {
    white-space: nowrap;

    &.attended {
      color: $color-dark-green;
    }

    &.not-attended {
      color: $color-red;
    }

    .icon {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1rem;
    }

    span {
      vertical-align: middle;
    }
  }

  .update-button {
    margin-right: 2rem;
    color: $color-dark-green;

    @media (max-width: $screen-sm-max) {
      margin-right: 1.5rem;
    }
  }

  .cancel-button {
    color: $color-red;
  }
}
