.back-to-top {
  position: fixed;
  right: 5rem;
  bottom: 10rem;
  z-index: 11;
  width: 5rem;
  height: 5rem;
  color: $color-white;
  background: $brand-primary;
  border-radius: 50%;
  transition: all 0.3s ease;
  transform: scale(0, 0);
  transform-origin: center center;

  &.show {
    transform: scale(1, 1);
  }

  .icon {
    position: absolute;
    top: calc(50% - 0.5rem);
    left: calc(50% - 1rem);
    width: 2rem;
    height: 1rem;
    color: inherit;
  }

  @media (max-width: $grid-float-breakpoint) {
    right: 2rem;
    bottom: 5rem;
    z-index: 11;
  }

  @media (max-width: $screen-xs-max) {
    right: 1rem;
    bottom: 4rem;
    width: 3.5rem;
    height: 3.5rem;

    .icon {
      top: calc(50% - 0.55rem);
      left: calc(50% - 0.75rem);
      width: 1.5rem;
    }
  }
}
