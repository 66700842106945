.outbid-notification {
  .modal__frame {
    max-width: 50rem;
    text-align: center;
  }

  .modal__body {
    padding-bottom: 0;
  }

  &__actions {
    margin: 2.5rem 0 0;

    .btn {
      margin: 0 0.75rem;
    }
  }

  p {
    font-size: $body-font-size;

    &:not(:last-child) {
      margin: 0 0 1.5rem;
    }
  }
}
