.sign-in {
  &__content {
    padding-bottom: 4rem;

    @media (max-width: $screen-sm-max) {
      padding-top: 2.5rem;
    }

    @media (max-width: $screen-xs-max) {
      padding-bottom: 2.5rem;
    }

    .section-heading {
      @media (max-width: $screen-xs-max) {
        margin: 0 0 2rem;
      }
    }

    .col-left {
      padding-right: 3rem;

      @media (max-width: $screen-xs-max) {
        padding-right: 2.5rem;
        padding-bottom: 2.5rem;
        margin-bottom: 2.5rem;
        border-bottom: 0.1rem solid $background-gray;
      }

      .btn {
        @media (max-width: $screen-mobile) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .col-right {
      padding-left: 3rem;

      @media (max-width: $screen-sm-max) {
        padding-left: 2.5rem;
      }

      .btn {
        float: right;

        @media (max-width: $screen-sm-max) {
          float: none;
        }

        @media (max-width: $screen-mobile) {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
