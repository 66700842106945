.sell-property {
  &__content {
    padding: 4rem 0;

    @media (max-width: $screen-xs-max) {
      padding: 2rem 0 2.5rem;
    }

    .map {
      padding: 0 0 73.5%;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__actions {
    padding-top: 3.5rem;
    border-top: 0.1rem solid $border-color;

    @media (max-width: $screen-xs-max) {
      padding-top: 2.5rem;
    }

    @media (max-width: $screen-mobile) {
      .btn {
        width: 100%;
      }
    }

    .col-back {
      float: left;
      padding-right: 0;
      padding-left: 1.5rem;

      @media (max-width: $screen-xs-max) {
        padding-left: 2.5rem;
      }

      @media (max-width: $screen-mobile) {
        float: none;
        padding-right: 2.5rem;
        margin-bottom: 1rem;
      }
    }

    .col-next {
      float: right;
      padding-right: 1.5rem;
      padding-left: 0;

      @media (max-width: $screen-xs-max) {
        padding-right: 2.5rem;
      }

      @media (max-width: $screen-mobile) {
        float: none;
        padding-left: 2.5rem;
      }
    }
  }

  &__open-days {
    padding: 2.5rem 0;
    border-top: 0.1rem solid $border-color;
    border-bottom: 0.1rem solid $border-color;

    .headline {
      margin-bottom: 2.5rem;
      font-size: 2.2rem;
      font-weight: $medium-font;

      sup {
        color: $brand-primary;
      }
    }

    .notification-box {
      margin-bottom: 2.5rem;
    }

    .input-row {
      @media (min-width: $screen-xs-max) {
        display: flex;
        justify-content: space-between;
      }

      margin: 0 -2rem;

      .form-group {
        width: 100%;
        padding: 0 2.5rem;

        @media (min-width: $screen-sm-min) {
          &:last-child {
            width: 20%;
          }
        }

        @media (max-width: $screen-sm-max) {
          padding: 0 0.5rem;

          &:first-child {
            padding-left: 2.5rem;
          }

          &:last-child {
            padding-right: 2.5rem;
          }
        }

        @media (max-width: $screen-xs-max) {
          &:nth-child(1) {
            padding-right: 2.5rem;
          }

          &:nth-child(2),
          &:nth-child(3) {
            float: left;
            width: 50%;
            margin-bottom: 1.5rem;
          }

          &:nth-child(2) {
            padding: 0 0 0 2.5rem;

            .form-control {
              border-right: 0;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }

          &:nth-child(3) {
            padding: 0 2.5rem 0 0;

            .form-control {
              border-left: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }

      .btn {
        display: block;
        color: $background-gray-dark;
        background: $background-gray-medium;

        span:first-child {
          display: none;
        }

        @media (max-width: $screen-xs-max) {
          width: 100%;
          margin-bottom: 2.5rem;
          clear: both;

          span:first-child {
            display: inline-block;
            margin-right: 1rem;
          }
        }

        .icon {
          width: 2rem;
          height: 2rem;
          color: $background-gray-dark;
        }
      }
    }

    .button-add {
      @media (max-width: $screen-xs-max) {
        width: 100%;
      }
    }
  }

  &__rights {
    padding: 2.5rem 0 0;

    .headline {
      margin-bottom: 2.5rem;
      font-size: 2.2rem;
      font-weight: $medium-font;
    }

    .notification-box {
      margin-bottom: 2.5rem;
    }

    .rule-paragraph {
      display: flex;

      span:first-child {
        margin-right: 2rem;
      }
    }

    p,
    p > span {
      line-height: 160%;
    }

    .form-group {
      .checkbox {
        margin-bottom: 0;
      }
    }
  }
}
