.checkbox {
  label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 1.5em;
    font-size: inherit;
    color: inherit;
    user-select: none;

    &::before {
      position: absolute;
      top: 0.1em;
      left: 0;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin: 0;
      content: "";
      border: 0.1rem solid $border-color;
      border-radius: $border-radius;
    }

    .icon {
      position: absolute;
      top: 0.3em;
      left: 0.2em;
      width: 0.6em;
      height: 0.6em;
      font-size: inherit;
      color: $brand-primary;
      transition: all 0.3s ease;
      transform: scale(0, 0);
    }
  }

  input {
    position: absolute;
    left: -9999rem;

    &:checked + label {
      .icon {
        transform: scale(1, 1);
      }
    }

    &:focus + label,
    & + label:focus {
      &::before {
        border-color: $brand-primary;
        outline: 0;

        @include outer-glow($brand-primary);
      }
    }
  }

  sup.required {
    color: $brand-primary;
  }
}
