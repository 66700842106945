.register-form {
  &__account-type {
    padding: 3.5rem 0 4rem;
    background: $background-gray-medium;
    box-shadow: inset 0 0.1rem 0.2rem 0 $background-gray;

    @media (max-width: $screen-xs-max) {
      padding: 2.7rem 0 1.5rem;
    }

    .section-heading {
      text-align: center;

      @media (max-width: $screen-xs-max) {
        margin: 0 0 2.5rem;
      }
    }

    .row {
      @media (min-width: $screen-md-min) {
        display: flex;

        & > div[class^="col-"] {
          display: flex;
        }
      }
    }

    .box {
      display: flex;
      padding: 3.5rem;
      background: $color-white;
      border: 0.2rem solid transparent;
      box-shadow: 0 0.2rem 0.2rem 0 $border-color;
      transition: all 0.3s ease;

      @media (max-width: $screen-sm-max) {
        padding: 2.8rem 2.5rem;
        margin-bottom: 1.5rem;
      }

      @media (max-width: $screen-xs-max) {
        padding: 2rem 2.5rem;
      }

      &:not(:last-of-type) {
        border-right-color: transparent;
      }

      .tick,
      .content {
        display: inline-block;
        vertical-align: top;
      }

      .tick {
        position: relative;
        width: 6.4rem;
        height: 6.4rem;
        margin-right: 4rem;
        background: #f2f2f2;
        border-radius: 50%;
        transition: all 0.3s ease;

        @media (max-width: $screen-xs-max) {
          display: none;
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 60%;
          height: 60%;
          content: "";
          border: 0.2rem solid $border-color;
          border-radius: 50%;
          transition: all 0.3s ease;
          transform: translate(-50%, -50%);
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 1.8rem;
          height: 1.8rem;
          color: $border-color;
          transition: all 0.3s ease;
          transform: translate(-50%, -50%);
        }
      }

      .content {
        width: calc(100% - 6.4rem - 4rem);

        @media (max-width: $screen-xs-max) {
          width: 100%;
          text-align: center;
        }

        .title {
          margin-bottom: 3rem;
          font-size: 2.8rem;
          font-weight: $medium-font;
          color: $text-second-color;

          @media (max-width: $screen-xs-max) {
            margin-bottom: 1rem;
            font-size: 2.4rem;
          }
        }
      }

      &.active {
        border: 0.2rem solid $brand-primary;

        .tick {
          background: $brand-primary;

          &::after {
            border-color: $color-white;
          }
        }

        .icon {
          color: $color-white;
        }
      }

      &.active,
      &:focus {
        @include outer-glow($brand-primary);
      }

      &:hover {
        border: 0.2rem solid $brand-primary;

        @include outer-glow($brand-primary);
      }
    }
  }

  &__content {
    padding: 4rem 0;

    @media (max-width: $screen-xs-max) {
      padding: 2rem 0 2.5rem;
    }

    .inline-column {
      @media (max-width: $screen-xs-max) {
        .form-control {
          border-radius: 0;
        }

        &:first-child {
          padding-right: 0;

          .form-control {
            border-top-left-radius: 0.4rem;
            border-bottom-left-radius: 0.4rem;
          }
        }

        &:last-child {
          padding-left: 0;

          .form-control {
            border-top-right-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
          }
        }

        &:nth-child(2):not(:last-child) {
          padding-right: 0;
          padding-left: 0;
        }

        &:not(:last-child) {
          .form-control {
            border-right: none;

            &:focus {
              border: 0.1rem solid $brand-primary;
            }
          }
        }
      }

      @media (max-width: $screen-landscape) {
        &.mobile-col {
          width: 100%;
          padding-right: 2.5rem;
          padding-left: 2.5rem;

          .form-control {
            border: 0.1rem solid $border-color;
          }
        }
      }
    }

    .actions {
      padding-top: 4rem;
      margin-top: 1.5rem;
      text-align: right;
      border-top: 0.1rem solid $border-color;

      @media (max-width: $screen-xs-max) {
        padding-top: 2.5rem;
        margin-top: 0;
      }

      @media (max-width: $screen-mobile) {
        .btn {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
