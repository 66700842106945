.terms {
  &__files {
    padding: 4rem 0;
    background: $background-gray-medium;
    box-shadow: inset 0 0.2rem 0.2rem 0 $box-shadow-color;

    .row {
      @media (min-width: $screen-sm-min) {
        display: flex;
      }
    }

    &--meta {
      margin-bottom: 4rem;
      font-size: 1.9rem;
      font-weight: $medium-font;
      color: $text-second-color;
      text-align: center;
    }

    .term-file {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 4rem;
      background: $color-white;
      border: 0.2rem solid $color-white;

      &__icon {
        width: 6.4rem;
        height: 6.4rem;
      }

      &:first-child {
        border-right: 0.1rem solid $background-gray;

        @media screen and (max-width: $screen-xs-max) {
          margin-bottom: 2rem;
          border-right: none;
        }
      }

      &:hover,
      &:focus {
        text-decoration: none;
        border: 0.2rem solid $brand-primary;
      }

      &__meta {
        padding-left: 3.5rem;

        .filename {
          margin-bottom: 1rem;
          font-size: 2.8rem;
          color: $text-second-color;
        }

        .filedesc {
          font-size: 1.7rem;
          font-weight: $medium-font;
          color: $brand-primary;
        }
      }
    }
  }
}
