.Tabs {
  &__Content {
    transition: all 0.3s ease;

    &.animate {
      display: none;

      &.active {
        display: block;
        opacity: 0;
      }

      &.show {
        opacity: 1;
      }
    }
  }
}
