.register-confirmation {
  @media (max-width: $screen-sm-max) {
    padding-top: 2.5rem;
  }

  .section-heading {
    @media (max-width: $screen-xs) {
      margin: 0 0 1.5rem;
      font-size: 1.8rem;
    }
  }

  &__details {
    padding: 3rem 0;
    margin: 3rem 0;
    border-top: 0.1rem solid $border-color;
    border-bottom: 0.1rem solid $border-color;

    @media (max-width: $screen-xs) {
      padding: 2rem 0;
      margin: 2.2rem 0;

      .col-table {
        &:first-child {
          margin-bottom: 2rem;
        }
      }
    }

    table {
      width: 100%;

      @media (min-width: $screen-sm-min) {
        max-width: 37rem;
      }

      tr:not(:last-child) {
        td {
          padding-bottom: 2rem;
        }
      }

      td {
        width: 50%;

        @media (max-width: $screen-xs) {
          display: inline-block;
          word-wrap: break-word;
        }

        &:first-child {
          font-weight: $medium-font;

          @media (max-width: $screen-xs) {
            width: 40%;
          }
        }

        &:last-child {
          @media (max-width: $screen-xs) {
            width: 60%;
            padding-left: 2rem;
          }
        }
      }
    }
  }

  &__terms {
    .form-group {
      display: block;
      padding: 0;
      margin: 0;

      .checkbox {
        display: inline;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }

      .help-block {
        position: relative;
      }
    }

    @media (max-width: $screen-xs) {
      > p {
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    padding: 3.5rem 0;
    margin-top: 3.5rem;
    border-top: 0.1rem solid $border-color;

    @media (max-width: $screen-xs) {
      padding: 2.5rem 0;
      margin-top: 2rem;
    }
  }
}
