.faq {
  &__content {
    padding-bottom: 4rem;

    @media (max-width: $screen-sm-max) {
      padding-top: 0;
      padding-bottom: 3rem;
    }

    @media (max-width: $screen-xs-max) {
      padding: 0;
    }

    .how-it-works-mobile {
      display: none;
      margin-top: 3rem;

      @media (max-width: $screen-sm-max) {
        display: block;
      }

      @media (max-width: $screen-xs-max) {
        margin-top: 0;
      }

      .how-it-works-button {
        padding: 1.9rem 8rem 2rem 2.5rem;
        border-radius: 0;
      }
    }
  }

  &__menu {
    display: block;
    width: 100%;
    margin: 0 0 4rem;
    overflow: hidden;
    border-radius: $border-radius;

    @media (max-width: $screen-sm-max) {
      margin: 0;
      border-radius: 0;

      & + .how-it-works-button {
        display: none;
      }
    }
  }

  &__menu-dropdown {
    padding: 2.5rem 0;

    select {
      width: 20rem;
      max-width: 80%;
      margin: 0 auto;
    }
  }

  &__menu-item {
    position: relative;
    display: block;
    width: 100%;
    padding: 1.9rem 4.6rem 1.9rem 2rem;
    font-size: 1.9rem;
    color: $text-color;
    user-select: none;
    background: $background-gray-medium;
    transition: all 0.3s ease;

    @media (max-width: $screen-sm-max) {
      font-size: 1.8rem;
    }

    @media (max-width: $screen-xs-max) {
      padding: 1.9rem 2.5rem;
    }

    &:not(:last-of-type) {
      border-bottom: 0.1rem solid $border-color;
    }

    &:hover,
    &.active {
      font-weight: $medium-font;
      color: $color-white !important;
      text-decoration: none;
      background: $brand-primary;

      .icon {
        opacity: 1;
      }
    }

    &:focus {
      color: $text-color;
      outline: 0 none;
      box-shadow: none;
    }

    .icon {
      position: absolute;
      top: calc(50% - 0.5rem);
      right: 2rem;
      width: 0.6rem;
      height: 1rem;
      color: $color-white;
      opacity: 0;
      transition: all 0.3s ease;

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }
}
