.home {
  .header {
    @media (max-width: $screen-xs-max) {
      background-image: url("/static/uploads/video-cover-mobile.jpg") !important;
    }

    @media (max-width: $grid-float-breakpoint) {
      &::before {
        display: none;
      }
    }
  }

  &__hero {
    position: relative;
    z-index: 3;
    padding-top: 8rem;
    padding-bottom: 25rem;

    @media (width <= 1650px) {
      padding-bottom: 15rem;
    }

    @media (width <= 1400px) {
      padding-top: 5rem;
      padding-bottom: 10rem;
    }

    @media (width <= 1300px) {
      padding-bottom: 5rem;
    }

    @media (max-width: $grid-float-breakpoint) {
      padding-top: 3rem;
      padding-bottom: 8rem;
    }

    @media (max-width: $screen-xs-max) {
      padding-top: 3rem;
      padding-bottom: 10rem;
    }
  }

  &__title {
    padding: 0 1.5rem;
    margin: 0 0 $spacing-main;
    font-size: 5rem;
    font-weight: $regular-font;
    color: $color-white;
    text-align: center;

    @media (width <= 1300px) {
      font-size: 4rem;
    }

    @media (max-width: $grid-float-breakpoint) {
      padding: 0;
      margin: 0 0 3rem;
      font-size: 3rem;
    }

    .slick-slide {
      transition: opacity 700ms cubic-bezier(0.8, 0.02, 0.42, 0.96) !important;
    }
  }

  &__subtitle {
    margin: 0 0 1rem;
    font-size: 2.5rem;
    line-height: 1.4;
    color: $color-white;
    text-align: center;

    @media (width <= 1300px) {
      font-size: 2.2rem;
    }

    @media (max-width: $screen-xs-max) {
      margin: 0 0 2rem;
      font-size: 2rem;
    }

    &:last-of-type {
      margin-bottom: 10rem;

      @media (width <= 1300px) {
        margin-bottom: 5rem;
      }

      @media (max-width: $screen-xs-max) {
        margin-bottom: 3rem;
      }
    }
  }

  &__scroll-down {
    position: absolute;
    top: 100%;
    left: calc(50% - 2.5rem);
    z-index: 11;
    width: 5rem;
    height: 5rem;
    overflow: hidden;
    color: $color-white;
    background: $brand-primary;
    border-radius: 50%;

    @media (max-width: $grid-float-breakpoint) {
      display: none;
    }

    .icon {
      position: absolute;
      top: 2rem;
      left: 1.5rem;
      width: 2rem;
      height: 1rem;
      color: inherit;
      transform: translate3d(0, 0, 0);
      animation: wobble-down 1s infinite;
    }

    &:hover {
      color: $color-white !important;
      background: darken($brand-primary, 15);
    }
  }

  &__video-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;

      @media (max-width: $grid-float-breakpoint) {
        display: none;
      }
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: "";
      background: $color-black;
      opacity: 0.5;

      @media (max-width: $grid-float-breakpoint) {
        opacity: 0.1;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: "";
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OUI5MjREMDBFM0RBMTFFNTk2REE5QkQ5RkQ3QUIzNUUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OUI5MjREMDFFM0RBMTFFNTk2REE5QkQ5RkQ3QUIzNUUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5QjkyNENGRUUzREExMUU1OTZEQTlCRDlGRDdBQjM1RSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5QjkyNENGRkUzREExMUU1OTZEQTlCRDlGRDdBQjM1RSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pi2bZksAAAAWSURBVHjaYmBgYPgPBAyMIAIEAAIMAEbUBvsLy2lTAAAAAElFTkSuQmCC);
    }
  }
}
