.contact-form {
  .submit-row {
    display: flex;
    align-items: center;
    float: none;
    width: 100%;
    padding: 0;
    margin: 0 auto;

    @media (max-width: $screen-xs-max) {
      display: block;
    }

    .submit-button {
      @media (max-width: $screen-xs) {
        width: 100%;
        min-width: 0 !important;
      }
    }
  }

  .newsletter {
    flex: 1;
  }

  .notification-box {
    margin-top: 4rem;
  }
}
