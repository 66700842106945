.my-account__tabs {
  padding-top: 3.5rem;
  background: #f2f2f2;
  border-top: 0.1rem solid $border-color;

  @media (max-width: $grid-float-breakpoint) {
    display: none;
  }

  .nav-tabs {
    padding: 0;
    margin: 0;
    font-size: 0;
    list-style: none;

    li {
      display: inline-block;
      font-size: $body-font-size;
      border-top: 0.2rem solid transparent;

      &:not(:last-child) {
        margin-right: 0.4rem;
      }

      a {
        display: inline-block;
        padding: 2rem;
        font-size: 1.9rem;
        color: $color-medium-gray;
        background: $background-gray;

        @media (width <= 1050px) {
          padding: 2rem 1.5rem;
          font-size: 1.8rem;
        }

        * {
          vertical-align: middle;
        }

        .icon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1.5rem;
          color: $color-medium-gray;
        }

        &:focus,
        &:hover {
          text-decoration: none;
        }
      }

      &.active {
        border-color: $brand-primary;

        a {
          color: $text-color;
          background: $color-white;

          .icon {
            color: $brand-primary;
          }
        }
      }
    }
  }
}
