.sell-property__tabs {
  padding: 4rem 0;
  background: $background-gray-medium;
  box-shadow: inset 0 0.1rem 0.2rem 0 $background-gray;

  @media (max-width: $screen-xs-max) {
    padding: 2.5rem 0 2rem;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .step {
    flex: 1;

    @media (max-width: $screen-xs-max) {
      &:not(.active) {
        display: none;
      }
    }

    .icon {
      position: relative;
      width: 10rem;
      height: 10rem;
      margin: 0 auto;
      background: $background-gray-medium;
      border: 0.2rem solid $border-color;
      border-radius: 50%;
      transition: all 0.3s ease;

      @media (max-width: $screen-xs-max) {
        width: 6.2rem;
        height: 6.2rem;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 99%;
        height: 99%;
        content: "";
        border: 0.2rem solid $color-white;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.3s ease;
        transform: translate(-50%, -50%);
      }

      &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4.2rem;
        height: 4.2rem;
        color: $border-color;
        transform: translate(-50%, -50%);
      }
    }

    &:not(:last-child) .icon::after {
      position: absolute;
      top: 50%;
      right: calc(-200% - 0.2rem);
      display: block;
      width: 200%;
      height: 0.2rem;
      content: "";
      background: $border-color;
      transform: translateY(-50%);

      @media (max-width: $screen-md-max) {
        right: calc(-150% - 0.2rem);
        width: 150%;
      }

      @media (max-width: $screen-xs-max) {
        content: none;
      }
    }

    .title {
      margin-top: 2rem;
      font-size: 1.9rem;
      color: $color-gray;
      text-align: center;
      transition: all 0.3s ease;

      @media (max-width: $screen-xs-max) {
        margin-top: 1.5rem;
        font-size: 1.8rem;
      }
    }

    &.active {
      .icon {
        background: $brand-primary;
        border-color: $brand-primary;

        &::before {
          opacity: 1;
        }

        &__content {
          color: $color-white;
        }
      }

      .title {
        font-weight: $medium-font;
        color: $brand-primary;
      }
    }
  }
}
