.location-input {
  position: relative;
  display: block;
  width: 100%;
  overflow: visible;
  background: $color-white;
  border-radius: $border-radius;

  &__input {
    position: relative;
    z-index: 4;
    width: 100%;
    padding: 0.6rem 2rem;
    font-size: inherit;
    line-height: 1;
    background: inherit;
    outline: none;
  }

  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3;
    display: none;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background: inherit;
  }

  &__list-item {
    padding: 1.8rem 3rem;
    font-size: 0.89em;
    user-select: none;
    outline: none;

    &:not(.no-results) {
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 0.1rem solid $border-color;
      }

      &:hover {
        background: $background-gray;
      }
    }

    &.active {
      background: $background-gray;
    }
  }

  &.active {
    .location-input__input {
      border-radius: $border-radius $border-radius 0 0;
    }

    .location-input__list {
      display: block;
      border-top: 0.1rem solid $border-color;
      border-radius: 0 0 $border-radius $border-radius;
      box-shadow: 0 0 0.5rem 0 rgba($color-black, 0.2);
    }
  }
}
