.cta-banner {
  color: $color-white;
  text-align: center;
  background: url("/static/images/bg-cta-sign-up.jpg") 50% 50% / cover no-repeat;

  &__content {
    padding: 6rem 0;

    @media (max-width: $screen-xs-max) {
      padding: 3rem 0;
    }
  }

  .section-heading {
    font-weight: 400;
    color: $color-white;
  }

  .btn {
    @media (min-width: $screen-sm-min) {
      margin: 1.5rem 1.5rem 0;
    }

    @media (max-width: $screen-xs-max) {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }
}
