.video {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: $border-radius;

  .poster {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: $color-white 50% 50% / cover no-repeat;
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7.5rem;
    height: 7.5rem;
    padding: 1.5rem;
    background: $brand-primary;
    border: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    &::-internal-media-controls-download-button {
      display: none;
    }

    &::-webkit-media-controls-enclosure {
      overflow: hidden;
    }

    &::-webkit-media-controls-panel {
      width: calc(100% + 30px); /* Adjust as needed */
    }
  }

  &.loaded {
    .poster {
      display: none;
    }
  }
}
