.countdown {
  display: inline-block;
  overflow: hidden;
  font-size: 1.9rem;
  font-weight: $medium-font;
  border-radius: $border-radius;

  &__num {
    display: inline-block;
    min-width: 2.3em;
    padding: 1.3rem 0.5rem 1.3rem 1rem;
    font-size: 1.8rem;
    line-height: 1;
    text-align: center;

    &:not(:last-of-type) {
      border-right: 0.1rem solid transparent;
    }

    small {
      margin-left: 0.2rem;
      font-family: $font-family-base;
      font-size: 1.3rem;
    }
  }

  &--dark {
    color: $color-white;
    background: $color-gray;

    .countdown__num:not(:last-of-type) {
      border-color: $border-color-dark;
    }
  }

  &--light {
    color: #7f7f7f;
    background: $background-gray-medium;
    box-shadow: inset 0 0.1rem 0.5rem -0.3rem rgb(0 0 0 / 0.75);

    .countdown__num:not(:last-of-type) {
      border-color: #d8d8d8;
    }
  }

  &--red {
    color: $color-white;
    background: $color-red;

    .countdown__num:not(:last-of-type) {
      border-color: #d8d8d8;
    }
  }

  &.inactive {
    opacity: 0;
  }
}
