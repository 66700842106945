.property-bids-tab {
  @media (max-width: $screen-xs-max) {
    margin: 0 -2.5rem;
  }

  &__header {
    @media (min-width: $grid-float-breakpoint) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width: $screen-xs-max) {
      padding: 0 2.5rem;
      margin: 0;
    }

    .summary {
      float: none;
      font-size: 1.7rem;

      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0 0 1.5rem;
      }
    }

    .timer {
      float: none;

      @media (max-width: $screen-xs-max) {
        padding: 0;
      }

      .property-end-timer {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    .bidders {
      display: inline-block;
      vertical-align: middle;
    }

    .bids-count {
      display: inline-block;
      padding: 0 0 0 1rem;
      margin: 0 0 0 1rem;
      vertical-align: middle;
      border-left: 0.1rem solid $border-color;
    }

    .bidders,
    .bids-count {
      .num {
        font-size: 1.9rem;
      }
    }
  }

  .bids-table {
    margin: 3rem 0 0;

    &__header,
    &__row {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $screen-xs-max) {
        display: block;
      }
    }

    &__header {
      color: $text-dark-light;
      background: $background-gray-medium;
      border: 0 solid $border-color;
      border-width: 0.1rem 0.1rem 0;

      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }

    .column {
      display: inline-block;
      padding: 1.5rem;
      font-size: $body-font-size;
      vertical-align: middle;

      &.index {
        min-width: 5.5rem;
        text-align: center;

        @media (max-width: $screen-xs-max) {
          width: 5.5rem;

          &::before {
            content: "#";
          }
        }
      }

      &:not(.index) {
        flex: 1;
      }

      @media (max-width: $screen-xs-max) {
        padding: 0 1rem;

        &.author {
          width: calc(50% - 2.75rem);
        }

        &.price {
          width: calc(50% - 2.75rem);
          padding: 0 2rem 0 1rem;
          text-align: right;
        }

        &.time {
          width: calc(100% - 5.5rem);
          padding-top: 1rem;
          margin: 0 0 0 5.5rem;
          color: $color-medium-gray;
        }

        &:not(.index) {
          flex: 0 1 auto;
        }
      }

      &:not(:last-of-type) {
        border-right: 0.1rem solid $border-color;

        @media (max-width: $screen-xs-max) {
          border-right: 0 none;
        }
      }
    }

    &__row {
      position: relative;
      border: 0 solid $border-color;
      border-width: 0.1rem 0.1rem 0;

      &:last-of-type {
        border-width: 0.1rem;
      }

      &:first-of-type {
        color: $background-green;
        background: $background-light-green;
        border-color: currentColor;

        @media (max-width: $screen-xs-max) {
          font-weight: $medium-font;
        }
      }

      &:nth-of-type(2) {
        border-top-color: $background-green;
      }

      @media (max-width: $screen-xs-max) {
        padding: 2rem 0;
        font-size: 0;
        border-width: 0 0 0.1rem;

        &:first-of-type {
          border-width: 0.1rem 0;
        }

        &:last-of-type {
          border-width: 0 0 0.1rem;
        }
      }
    }

    &__footer {
      margin: 2rem 0;
      color: $text-color;
      border-bottom: 0.1rem solid $border-color;

      h3 {
        margin-bottom: 2rem;
        color: $color-black;
      }

      p {
        margin-bottom: 0.5rem;

        &:last-of-type {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
