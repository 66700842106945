.property-question-tab {
  .submit {
    padding: 1.4rem 2rem;
    color: $color-white;
    background: $brand-primary;
    border: 0 none;
    border-radius: $border-radius;
    outline: none;
    box-shadow: none;

    .icon {
      width: 1.4rem;
      height: 1.2rem;
      margin: 0 0 0 1.5rem;
      color: inherit;
      vertical-align: baseline;
      transition: all 0.3s ease;
      transform: translate(0, 0);
    }

    &:hover {
      .icon {
        transform: translate(1rem, 0);
      }
    }

    &.submitting {
      .icon {
        transform: translate(10rem, 0);
      }
    }
  }

  .accept-rules {
    position: absolute;
    left: -9999rem;
  }

  .submit-row {
    float: none;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    text-align: right;
  }

  .notification-box {
    margin-top: 3rem;
  }
}
