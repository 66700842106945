.home__recently-sold {
  position: relative;
  padding: 5.5rem 0 6rem;
  background: $background-gray-medium;

  @media (max-width: $screen-sm-max) {
    padding: 3rem 0;
  }

  &::before {
    position: absolute;
    top: -0.2rem;
    left: 0;
    width: 100%;
    height: 0.2rem;
    content: "";
    box-shadow: inset 0 0.1rem 0.2rem 0 #d3d3d3;
  }

  &__inner {
    position: relative;
    font-size: 0;
  }

  .section-heading {
    margin: 0 0 6rem;
    font-weight: $light-font;
    text-align: center;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 3rem;
    }
  }

  .slider-container {
    .arrow {
      width: 100%;
    }
  }

  &__properties {
    display: inline-block;
    width: 50%;
    font-size: $body-font-size;
    vertical-align: middle;

    @media (max-width: $screen-sm-max) {
      width: 100%;
      padding-bottom: 5rem;
    }

    .arrow {
      position: relative;
      width: 100%;
      height: 4rem;

      @media (max-width: $screen-sm-max) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;

        .icon {
          transform: rotate(-90deg);
          transform-origin: center center;
        }

        &-right {
          left: 50%;
        }
      }

      .icon {
        position: absolute;
        top: calc(50% - 0.7rem);
        left: calc(50% - 1.2rem);
        width: 2.4rem;
        height: 1.4rem;
        color: $text-dark-light;
      }
    }

    .slider {
      background: $color-white;
      border-radius: $border-radius;
    }
  }

  &__property {
    width: 100%;
    padding: 2rem 2.5rem;
    color: $text-second-color;
    cursor: pointer;
    border-bottom: 0.1rem solid $border-color;
    transition: all 0.3s ease;

    @media (width <= 1024px) {
      padding: 1.5rem 2.5rem;
    }

    @media (max-width: $screen-sm-max) {
      padding: 3rem 1.5rem;
      border-bottom: 0 none;
    }

    @media (max-width: $screen-sm-max) {
      padding: 1.2rem 1.5rem 1.5rem;
      border-bottom: 0 none;
    }

    @media (min-width: $screen-sm-max) {
      &.active,
      &:hover {
        color: $color-white;
        background: $brand-primary;

        .property-details {
          &__item {
            border-color: $color-white;

            &:last-of-type {
              color: $color-white;
            }
          }
        }
      }
    }

    .property-title {
      width: 100%;
      margin: 0 0 1.5rem;
      overflow: hidden;
      font-size: 1.9rem;
      font-weight: $medium-font;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .property-details {
      display: block;
      font-size: 0;
      text-align: left;

      .bids {
        min-width: 7rem;
      }

      &__item {
        display: inline-block;
        font-size: 1.7rem;
        vertical-align: middle;

        @media (max-width: $screen-sm-max) {
          font-size: 1.4rem;
        }

        &:not(:first-of-type) {
          padding-left: 1.6rem;
          margin-left: 1.6rem;
          border-left: 0.1rem solid $border-color;
        }

        &:last-of-type {
          color: $brand-primary;

          @media (max-width: $screen-sm-max) {
            display: block;
            padding: 0;
            margin: 1.4rem 0 0;
            border: 0 none;
          }

          a {
            color: inherit;
          }
        }
      }
    }
  }

  &__preview {
    display: inline-block;
    width: calc(50% + 3rem);
    height: 0;
    padding-bottom: calc(29.5% + 5.5rem);
    margin-right: -3rem;
    font-size: $body-font-size;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: $border-radius;

    @media (width <= 1024px) {
      padding-bottom: calc(29.5% + 6.5rem);
    }

    @media (max-width: $screen-sm-max) {
      width: 100%;
      padding-bottom: 75%;
    }
  }
}
