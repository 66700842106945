.how-it-works {
  &__content {
    padding: 4rem 0;
    background: $background-gray-medium;
    box-shadow: inset 0 0.1rem 0.2rem 0 $background-gray;

    @media (max-width: $screen-xs-max) {
      padding-top: 2.7rem;
      padding-bottom: 1.5rem;
    }

    .section-heading {
      @media (max-width: $screen-xs-max) {
        margin: 0 0 3rem;
      }
    }

    .row {
      @media (min-width: $screen-md-min) {
        display: flex;
      }
    }

    .box {
      position: relative;
      display: flex;
      height: 100%;
      padding: 3.5rem 4rem;
      background: $color-white;
      border: 0.2rem solid transparent;
      box-shadow: 0 0.2rem 0.2rem 0 $border-color;
      transition: all 0.3s ease;

      @media (max-width: $screen-sm-max) {
        padding: 2.8rem 2.5rem;
        margin-bottom: 1.5rem;
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:not(:last-of-type) {
        border-right-color: transparent;
      }

      .icon,
      .content {
        display: inline-block;
        vertical-align: top;
      }

      .icon {
        width: 6.5rem;
        height: 6.5rem;
        margin-right: 3.5rem;
        color: $brand-primary;

        @media (max-width: $screen-xs-max) {
          display: none;
        }
      }

      .content {
        width: calc(100% - 6.4rem - 4rem);

        @media (max-width: $screen-xs-max) {
          width: 100%;
          text-align: center;
        }

        .title {
          margin-bottom: 3rem;
          font-size: 2.8rem;
          font-weight: $medium-font;
          color: $text-second-color;

          @media (max-width: $screen-xs-max) {
            margin-bottom: 1rem;
            font-size: 2.4rem;
          }
        }
      }

      &:hover {
        border: 0.2rem solid $brand-primary;
      }

      &:focus {
        @include outer-glow($brand-primary);
      }
    }
  }

  .integrations {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &__intro {
    padding-bottom: 3rem;
    border-bottom: 0.1rem solid $border-color;

    @media (max-width: $screen-sm-max) {
      padding-top: 2.5rem;
      padding-bottom: 1rem;

      .col-intro {
        margin-bottom: 3rem;
      }
    }

    @media (max-width: $screen-xs-max) {
      padding-bottom: 2.5rem;

      .col-intro {
        margin-bottom: 2rem;
      }
    }

    .section-heading {
      @media (max-width: $screen-xs-max) {
        margin: 0 0 1.3rem;
      }
    }

    .icon-chevron {
      width: 0.9rem;
      height: 0.9rem;
      margin-left: 0.5rem;
      color: $brand-primary;
    }
  }

  &__details {
    padding: 4rem 0;
    background: $color-white;

    @media (max-width: $screen-xs-max) {
      padding: 4rem 0 2rem;

      .slider {
        margin-bottom: 3rem;
      }

      .arrow {
        position: absolute;
        top: 4rem;
        z-index: 1;
        color: $text-dark-light;
        cursor: pointer;

        .icon {
          width: 2.5rem;
          height: 2.5rem;
        }

        &-right {
          right: 3rem;
        }

        &-left {
          left: 3rem;
        }
      }
    }
  }

  &__point {
    margin-bottom: 3rem;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 5rem;

      .image,
      .content {
        display: inline-block;
        vertical-align: top;
      }
    }

    @media (max-width: $screen-xs-max) {
      margin-bottom: 0;
    }

    .image {
      position: relative;
      width: 13rem;
      height: 13rem;
      margin-right: 2.5rem;
      border: 0.2rem dotted $brand-primary;
      border-radius: 50%;

      @media (max-width: $screen-xs-max) {
        width: 9.5rem;
        height: 9.5rem;
        margin: 0 auto;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7rem;
        height: 6rem;
        color: $brand-primary;
        transform: translate(-50%, -50%);
      }
    }

    .content {
      width: calc(100% - 16rem);
      padding-right: 4rem;

      @media (max-width: $screen-xs-max) {
        width: 100%;
        padding-right: 0;
        margin-top: 2rem;
        text-align: center;
      }

      .section-heading {
        @media (max-width: $screen-xs-max) {
          margin-bottom: 1.5rem;
        }

        .number {
          margin-right: 2rem;
          color: $brand-primary;
        }
      }
    }
  }

  &__sidebar {
    &__item {
      &:not(:last-child) {
        margin-bottom: 4rem;

        @media (max-width: $screen-xs-max) {
          margin-bottom: 2rem;
        }
      }

      .section-heading {
        @media (max-width: $screen-xs-max) {
          margin-bottom: 2rem;
        }
      }
    }

    &__item {
      &.video-item {
        @media (max-width: $screen-xs-max) {
          margin-right: -2.5rem;
          margin-bottom: 0;
          margin-left: -2.5rem;

          .section-heading {
            padding: 0 2.5rem;
          }

          .video {
            border-radius: 0;
          }
        }
      }

      &.informations-item {
        @media (max-width: $screen-xs-max) {
          margin-right: -2.5rem;
          margin-left: -2.5rem;

          .section-heading {
            padding: 0 2.5rem;
          }
        }
      }
    }

    &__tabs {
      &__items {
        display: flex;
      }

      @media (max-width: $screen-xs-max) {
        .Tabs {
          &__Content {
            .text-content {
              padding: 0 2.5rem;
            }
          }
        }
      }

      .tab-item {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 2rem;
        color: $text-second-color;
        text-align: center;
        background: $background-gray;

        @media (max-width: $screen-sm-max) {
          padding: 2rem 1rem;
        }

        span {
          align-self: center;
        }

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &.active {
          color: $color-white;
          background: $brand-primary;

          &::after {
            opacity: 1;
          }
        }

        &::after {
          position: absolute;
          bottom: -0.7rem;
          left: 50%;
          display: block;
          width: 0;
          height: 0;
          content: "";
          border-top: 0.7rem solid $brand-primary;
          border-right: 0.7rem solid transparent;
          border-left: 0.7rem solid transparent;
          opacity: 0;
          transition: opacity 0.3s ease;
          transform: translateX(-50%);
        }
      }

      .tab-content {
        margin-top: 3.5rem;

        @media (max-width: $screen-xs-max) {
          margin-top: 2.5rem;
        }
      }
    }
  }

  &__lead {
    position: relative;
    padding: 3rem 2.5rem;
    color: $color-white;

    @media (max-width: $screen-sm-max) {
      display: inline-block;
      width: calc(50% - 0.5rem);
    }

    @media (max-width: $screen-xs-max) {
      display: block;
      width: calc(100% + 6rem);
      padding: 2.3rem 2.5rem;
      margin-right: -3rem;
      margin-left: -3rem;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;

      @media (max-width: $screen-sm-max) {
        margin-right: 1rem;
        margin-bottom: 0;
      }

      @media (max-width: $screen-xs-max) {
        margin-right: 0;
        margin-bottom: 0;
      }
    }

    .title,
    .desc {
      padding-right: 9rem;
    }

    .title {
      margin-bottom: 2.5rem;
      font-size: 2.2rem;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 1rem;
        font-size: 1.8rem;
      }
    }

    .desc {
      line-height: 1.438;

      a {
        font-weight: $medium-font;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &--image {
      background-repeat: no-repeat;
      background-size: cover;

      a {
        color: white;
      }

      .desc {
        padding-right: 2rem;
      }
    }

    &--green {
      background: #4eb63e;

      a {
        color: #bbfb68;
      }
    }

    &--blue {
      background: #2ca1c6;

      a {
        color: #99e7ff;
      }
    }
  }
}
