.pagination__container {
  padding: 4rem 0;
  border-top: 0.1rem solid $border-color;

  @media (max-width: $screen-xs-max) {
    padding: 3rem 0;
  }

  .wrapper {
    @media (min-width: $screen-sm-min) {
      display: flex;
      place-content: space-between center;

      & > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .pagination {
    margin: 0 auto;

    @media (max-width: $screen-xs-max) {
      display: block;
    }

    &__numeric {
      flex: 1;
      width: 100%;

      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }

    &__prev,
    &__next {
      font-size: 1.7rem;
      font-weight: $medium-font;
      color: $brand-primary;
      background: transparent;
      border: 0;

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }

    @media (max-width: $screen-xs-max) {
      &__prev__container {
        float: left;
      }

      &__next__container {
        float: right;
      }
    }

    .previous,
    .next {
      display: none;
    }

    & > li {
      cursor: pointer;

      & > a {
        padding: 0.7rem 1.4rem;
        margin: 0 0.4rem;
        border-color: $border-color;
        border-radius: $border-radius;

        &:hover,
        &:focus {
          color: $color-white;
          background-color: $brand-primary;
          border-color: $brand-primary;
        }
      }

      &.active {
        a {
          border-color: $brand-primary;
        }
      }
    }
  }

  &:not(.with-numeric) {
    .pagination__prev__container {
      flex: 1;
    }
  }
}
