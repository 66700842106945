.submit-button {
  &.done {
    &,
    &:hover,
    &:focus {
      background: $color-dark-green;
    }
  }

  &.error {
    &,
    &:hover,
    &:focus {
      background: $color-red;
    }
  }

  .tick,
  .spinner,
  .error {
    width: 1.6rem;
    height: 1.6rem;
    color: $color-white;
  }

  .spinner {
    animation: rotating 2s 0.25s linear infinite;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
