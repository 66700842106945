.integrations {
  padding: 5rem 0;

  @media (max-width: $screen-xs-max) {
    padding: 2rem 0 2.5rem;

    p {
      font-size: 1.8rem;
      line-height: 1.444;
    }
  }

  .container {
    @media (min-width: $screen-lg-min) {
      display: flex;
      align-items: center;
    }
  }

  &__text {
    flex: 1;
    font-size: 1.9rem;

    @media (max-width: $screen-md-max) {
      text-align: center;
    }

    & > * {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__websites {
    @media (max-width: $screen-md-max) {
      margin-top: 2rem;
      text-align: center;
    }
  }

  &__icon {
    @media (min-width: $screen-lg) {
      margin: 0 2rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: $screen-md-max) {
      margin: 0 1.5rem;
    }

    &__rightmove {
      width: 16rem;
      height: 4rem;

      @media (max-width: $screen-xs-max) {
        width: 100%;
        margin: 0 0 1.5rem;
      }
    }

    &__zoopla {
      width: 16rem;
      height: 3.8rem;

      @media (max-width: $screen-md-max) {
        margin-left: 0;
      }

      @media (max-width: $screen-xs) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1.5rem;
      }
    }

    &__primelocation {
      width: 22rem;
      height: 3.7rem;

      @media (max-width: $screen-md-max) {
        margin-right: 0;
      }

      @media (max-width: $screen-xs) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
