.dropdown {
  position: relative;
  display: block;
  width: 100%;
  overflow: visible;
  font-size: 1.6rem;
  background: $color-white;
  border: 0.1rem solid $input-border;
  border-radius: $border-radius;
  outline: none;
  box-shadow: none;

  &__value {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: 5.2rem;
    padding: 0 3rem;
    font-size: inherit;
    line-height: 5.2rem;
    cursor: pointer;
    user-select: none;
    background: inherit;
    border-radius: $border-radius;

    &-label {
      display: inline-block;
      width: calc(100% - 4.4rem);
      margin: 0 3rem 0 0;
      overflow: hidden;
      color: $input-color;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }

    &-icon {
      display: inline-block;
      width: 1.3rem;
      height: 1.3rem;
      margin: 0;
      color: $text-dark-light;
      vertical-align: middle;
      transition: all 0.3s ease;
      transform: rotate(0deg);
      transform-origin: center center;
    }

    &__close {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      display: none;
      width: 100%;
      height: 100%;
      cursor: pointer;
      outline: none;
    }
  }

  &__options {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    width: 100%;
    max-height: 35rem;
    padding: 0;
    margin: 0;
    overflow: hidden auto;
    font-size: inherit;
    list-style: none;
    background: inherit;
    border-top: 0.1rem solid $border-color;
    border-radius: 0 0 $border-radius $border-radius;
  }

  &__option {
    display: block;
    width: 100%;
    padding: 1.8rem 2rem 1.6rem;
    font-size: 0.89em;
    user-select: none;
    outline: none;

    &:not(:last-of-type) {
      border-bottom: 0.1rem solid $border-color;
    }

    &.selected {
      background: $border-color;

      .icon {
        color: $brand-primary;
      }
    }

    &.active {
      &:not(.selected) {
        background: $border-color;
      }
    }

    &:not(.no-options) {
      cursor: pointer;

      &:hover,
      &:active {
        background: $background-gray;
      }
    }

    .icon,
    .label {
      display: inline-block;
      vertical-align: middle;
    }

    .label {
      width: calc(100% - 5rem);
    }

    .icon {
      width: 2rem;
      height: 2rem;
      margin: 0 0 0 3rem;
      color: transparent;
    }
  }

  &.active {
    .dropdown__value {
      z-index: 10;
      border-radius: $border-radius $border-radius 0 0;

      &__close {
        display: block;
      }

      &-icon {
        transform: rotate(180deg);
      }
    }

    .dropdown__options {
      display: block;
      box-shadow: 0 0 0.5rem 0 rgba($color-black, 0.2);
    }
  }

  &.placeholder {
    .dropdown__value {
      &-label {
        color: $text-dark-light;
      }
    }
  }
}
