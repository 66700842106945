.property-attachments {
  padding-top: 3.5rem;
  padding-bottom: 1.5rem;
  border-top: 0.1rem solid $border-color;

  @media (max-width: $screen-xs-max) {
    padding: 2rem 0 0.5rem;
    border-top: none;
  }

  &:empty {
    display: none;
  }

  .attachment {
    display: inline-block;
    float: none;
    margin-bottom: 2rem;
    vertical-align: top;

    &__icon {
      display: inline-block;
      width: 3.8rem;
      height: 3.8rem;
      margin: 0 1.8rem 0 0;
      color: $brand-primary;
      vertical-align: top;
    }

    &__details {
      display: inline-block;
      width: calc(100% - 5.6rem);
      vertical-align: top;
    }

    &__name,
    &__url {
      display: block;
    }

    &__name {
      margin: 0 0 1rem;
      line-height: 1.25;
    }
  }
}
