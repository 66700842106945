.home__our-way {
  padding: 4rem 0;
  background: $background-gray-medium;
  border-bottom: 0.1rem solid $border-color;

  @media (max-width: $screen-xs-max) {
    padding: 0;
  }

  & > .container {
    width: 100%;

    @media (width >= 1630px) {
      width: 160rem;
    }

    @media (min-width: $screen-md-min) {
      & > .row {
        display: flex;
        align-items: center;
      }
    }
  }

  &__content {
    padding: 5rem 4.5rem;

    @media (max-width: $screen-md-max) {
      padding: 3rem 2.5rem;

      .section__title {
        margin-bottom: 3rem;
      }
    }

    @media (max-width: $screen-xs-max) {
      padding: 2.3rem 2.5rem 2.5rem;

      .section__title {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__reasons {
    .text-content {
      margin-bottom: 5rem;
      font-size: 1.7rem;

      @media (max-width: $screen-md-max) {
        margin-bottom: 3rem;
      }

      @media (max-width: $screen-xs-max) {
        margin-bottom: 1.5rem;
      }
    }

    @media (min-width: $screen-md-min) {
      max-width: 63rem;
    }

    @media (max-width: $screen-xs-max) {
      position: relative;

      .arrow {
        position: absolute;
        top: 50%;
        color: $text-dark-light;
        cursor: pointer;
        transform: translateY(calc(-50% - 1.25rem));

        .icon {
          width: 2.5rem;
          height: 2.5rem;
        }

        &-right {
          right: 0;
        }

        &-left {
          left: 0;
        }
      }
    }

    .way-column {
      float: left;
      width: 50%;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      margin-bottom: 3rem;

      @media (max-width: $screen-md-max) {
        margin-bottom: 2rem;
      }

      @media (max-width: $screen-xs-max) {
        width: 100%;
        padding-right: 2.5rem;
        padding-left: 2.5rem;
      }

      &:nth-child(2n + 1) {
        clear: both;
      }
    }

    .icon,
    .text {
      display: inline-block;
      vertical-align: middle;

      @media (max-width: $screen-xs-max) {
        display: block;
        margin: 0 auto;
        text-align: center;
      }
    }

    .icon {
      width: 6rem;
      height: 6rem;

      &--1 {
        color: #44b6da;
      }

      &--2 {
        color: #ec444b;
      }

      &--3 {
        color: #f27527;
      }

      &--4 {
        color: #9cc430;
      }
    }

    .text {
      width: calc(100% - 6rem);
      padding-left: 2.5rem;
      font-size: 1.9rem;
      font-weight: $light-font;

      @media (width <= 1024px) {
        font-size: 1.8rem;
      }

      @media (max-width: $screen-sm-max) {
        font-size: 1.7rem;
      }

      @media (max-width: $screen-xs-max) {
        padding-left: 0;
        margin-top: 2rem;
      }

      span {
        font-weight: $medium-font;
      }
    }
  }

  &__buttons {
    overflow: hidden;

    @media (min-width: $screen-md-min) {
      max-width: 63rem;
      margin-top: 2rem;
    }

    .button-column {
      float: left;
      width: 50%;

      @media (max-width: $screen-xs-max) {
        .btn {
          .arrow {
            display: none;
          }
        }
      }

      &:first-child {
        padding-right: 1rem;

        @media (max-width: $screen-xs-max) {
          padding-right: 0.05rem;

          .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      &:last-child {
        padding-left: 1rem;

        @media (max-width: $screen-xs-max) {
          padding-left: 0.05rem;

          .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  &__video {
    position: relative;
    padding: 0;

    .text {
      position: absolute;
      right: 0;
      bottom: 4.5rem;
      left: 0;
      z-index: 3;
      padding: 0 2.5rem;
      margin: 0 auto;
      font-size: 3.2rem;
      font-weight: $light-font;
      color: $color-white;
      text-align: center;

      @media (width <= 1180px) {
        bottom: 2.5rem;
        font-size: 2.8rem;
      }

      @media (max-width: $screen-xs-max) {
        bottom: 1.3rem;
        padding: 0 1.5rem;
        font-size: 1.7rem;
      }
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: 25%;
      content: "";
      background: linear-gradient(
        to top,
        rgb(0 0 0 / 0.5) 0%,
        rgb(0 0 0 / 0) 100%
      );
    }

    &.playing {
      .text {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
}
