.home__reasons {
  padding: 5.5rem 0 2rem;
  font-size: 0;
  border-bottom: 0.1rem solid $border-color;

  .reason-column {
    display: inline-block;
    width: 50%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-size: $font-size-base;
    vertical-align: top;

    @media (max-width: $screen-xs-max) {
      width: 100%;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }

  &__single {
    margin-bottom: 3.5rem;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 2rem;
    }

    .icon,
    .text {
      @media (min-width: $screen-md-min) {
        display: inline-block;
        vertical-align: top;
      }
    }

    .icon-chevron {
      width: 0.9rem;
      height: 0.9rem;
      margin-left: 0.5rem;
      color: $brand-primary;
    }

    .icon {
      position: relative;
      width: 11.6rem;
      height: 11.6rem;
      margin-right: 3rem;
      background: $color-black;
      border-radius: 50%;

      @media (max-width: $screen-sm-max) {
        margin: 0 auto 2rem;
      }

      @media (max-width: $screen-xs-max) {
        width: 8.6rem;
        height: 8.6rem;
      }

      .svg-container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6rem;
        height: 6rem;
        color: $color-white;
        transform: translate(-50%, -50%);

        @media (max-width: $screen-xs-max) {
          width: 4.5rem;
          height: 4.5rem;
        }
      }

      &.icon--1 {
        background: #44b6da;
        box-shadow: 0 0 0 0.01rem #44b6da;
      }

      &.icon--2 {
        background: #ec444b;
        box-shadow: 0 0 0 0.01rem #ec444b;
      }

      &.icon--3 {
        background: #9cc430;
        box-shadow: 0 0 0 0.01rem #9cc430;
      }

      &.icon--4 {
        background: #f27021;
        box-shadow: 0 0 0 0.01rem #f27021;
      }
    }

    .text {
      @media (min-width: $screen-md-min) {
        max-width: calc(100% - 15rem);
      }

      .text-content {
        font-size: 1.7rem;
        line-height: 1.647;

        @media (max-width: $screen-sm-max) {
          text-align: center;
        }

        @media (max-width: $screen-xs-max) {
          font-size: 1.6rem;
          line-height: 1.438;
        }

        a {
          font-weight: $medium-font;
        }
      }

      .head {
        margin-bottom: 2.5rem;
        font-size: 2.4rem;
        font-weight: $medium-font;
        line-height: 1;

        @media (max-width: $screen-sm-max) {
          text-align: center;
        }

        @media (max-width: $screen-xs-max) {
          margin-bottom: 1.5rem;
          font-size: 1.8rem;
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    position: relative;

    .arrow {
      position: absolute;
      top: calc(50% - 1.25rem);
      z-index: 1;
      color: $text-dark-light;
      cursor: pointer;
      transform: translateY(calc(-50% - 1.25rem));

      .icon {
        width: 2.5rem;
        height: 2.5rem;
      }

      &-right {
        right: 3rem;
      }

      &-left {
        left: 3rem;
      }
    }
  }
}
