.open-days {
  .book-visit {
    display: inline-block;
    padding: 0 0 0 2rem;
    margin: 0 0 0 2rem;
    font-size: 1.9rem;
    color: $background-green;
    background: none;
    border: 0 none;
    border-left: 0.1rem solid $border-color;

    @media (width <= 450px) {
      display: block;
      padding: 0;
      margin: 0.5rem 0 0;
      border: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .section-heading {
    margin: 0 0 2rem;
    font-size: 2.2rem;
  }

  &__list {
    padding-top: 1rem;
  }

  &__text {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
  }

  &__item {
    margin-bottom: 2rem;

    .icon,
    .details {
      float: none;
    }

    .icon {
      display: inline-block;
      width: 3.8rem;
      height: 3.8rem;
      margin: 0 2rem 0 0;
      color: $background-green;
      vertical-align: middle;

      &.red {
        color: $color-red;
      }

      &.action {
        cursor: pointer;
      }
    }

    .details {
      display: inline-block;
      width: calc(100% - 5.8rem);
      vertical-align: middle;

      p {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        &.error {
          line-height: 1.2;
          color: $color-red;

          a {
            font-weight: $medium-font;
            color: inherit;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
