.terms-of-use {
  padding-bottom: 4rem;

  ol {
    list-style: none;
    counter-reset: toc;

    & > li {
      position: relative;
      margin-bottom: 2rem;
      text-align: justify;
      counter-increment: toc;

      &::before {
        position: absolute;
        top: 0;
        left: -3rem;
        content: counters(toc, ".") ".";
      }

      ol {
        & > li {
          position: relative;
          margin: 0;

          &::before {
            position: absolute;
            top: 0;
            left: -4rem;
            content: counters(toc, ".");
          }

          ul {
            li {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .text-content {
    @media (max-width: $screen-sm-max) {
      padding-top: 25px;
    }

    ul {
      margin-bottom: 1rem;
    }
  }
}
