$desktop: 420px;

.cookie-box {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  color: #fff;
  background: $brand-primary;
  transition: all 0.5s ease;

  &.hidden {
    transform: translateY(100%);
  }

  &__inner {
    padding: 15px;

    @media (min-width: $desktop) {
      display: flex;
      align-items: center;
      padding: 25px 15px;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    align-items: center;

    &__inner {
      flex: 1;
    }
  }

  &__icon {
    position: relative;
    width: 44px;
    margin-right: 20px;

    svg {
      position: absolute;
      top: 50%;
      width: 44px;
      height: 44px;
      transform: translateY(-50%);
    }
  }

  &__title {
    padding: 0;
    margin: 0;
    font-size: 17px;
    font-weight: 700;
    color: currentColor;
    text-align: left;
  }

  &__desc {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    color: currentColor;
    text-align: left;

    a {
      color: currentColor;
      text-decoration: underline;
      white-space: nowrap;

      &:hover {
        color: currentColor;
        text-decoration: underline;
      }
    }
  }

  &__buttons {
    @media (min-width: $desktop) {
      padding-left: 15px;
    }
  }

  &__button {
    display: block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 700;
    color: currentColor;
    white-space: nowrap;
    cursor: pointer;
    background: transparent;
    border: 2px solid currentColor;
    border-radius: 24px;

    @media (max-width: $desktop) {
      margin: 15px auto 0;
    }

    .svg-container {
      width: 17px;
      height: 17px;
      margin-top: -2px;
      margin-left: 5px;
    }
  }
}
