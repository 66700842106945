.layout {
  overflow-x: hidden;

  &.loading {
    max-height: 100vh;
    overflow: hidden;

    & > :not(.preloader) {
      opacity: 0;
    }
  }

  @media (max-width: $grid-float-breakpoint) {
    .wrap {
      z-index: 1;
      overflow: hidden auto;
      background: $body-bg;
      transition: transform 0.3s ease;
      transform: translate(0, 0);
    }

    .mobile-menu {
      transition: transform 0.3s ease;
      transform: translate($mobile-menu-width, 0);
    }

    &.menu-open {
      .mobile-menu {
        transform: translate(0, 0);
      }

      .wrap {
        max-height: 100vh;
        overflow-y: hidden;
        transform: translate(-$mobile-menu-width, 0);
      }
    }
  }
}
