.fee-calculator {
  padding: 6rem 0;
  background: $background-gray;

  @media (max-width: $screen-xs-max) {
    padding: 3rem 0;
  }

  .section-heading {
    font-size: 4rem;
    text-align: center;

    @media (max-width: $screen-xs-max) {
      font-size: 2.5rem;
    }
  }

  &__slider-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 3.5rem;

    @media (max-width: $screen-xs-max) {
      display: block;
      margin: 0 0 1.5rem;
    }

    .label {
      font-size: 1.9rem;
      color: $text-color;

      @media (max-width: $screen-xs-max) {
        display: block;
        margin: 0 0 1rem;
        text-align: center;
      }
    }

    .value {
      font-size: 2.8rem;
      font-weight: $medium-font;

      @media (max-width: $screen-xs-max) {
        display: block;
        text-align: center;
      }
    }
  }

  &__slider {
    margin: 0 0 4rem;
  }

  @media (min-width: $screen-xs-max) {
    .row {
      & > .col-sm-6 {
        &:nth-child(2n-1) {
          padding-right: 3.5rem;
        }

        &:nth-child(2n) {
          padding-left: 3.5rem;
        }
      }
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 4.5rem;
    font-weight: $medium-font;

    @media (max-width: $screen-xs-max) {
      display: block;
      margin: 0 0 2.5rem;
    }

    .summary-item {
      @media (max-width: $screen-xs-max) {
        margin: 0 0 2.5rem;
        text-align: center;
      }

      @media (min-width: $screen-sm-min) {
        &:not(:last-child) {
          padding-right: 5rem;
        }
      }
    }

    .label {
      display: inline-block;
      margin: 0 3rem 0 0;
      font-size: 2.8rem;

      @media (max-width: $screen-xs-max) {
        display: block;
        margin: 0 0 1.5rem;
        font-size: 2rem;
      }
    }

    .value {
      display: inline-flex;
      align-items: center;
      min-height: 7rem;
      padding: 0 2rem;
      font-size: 4rem;
      letter-spacing: 0.3rem;
      background: $color-white;
      border-radius: $border-radius;

      @media (max-width: $screen-xs-max) {
        display: flex;
        justify-content: center;
        min-height: 5.4rem;
        font-size: 2.8rem;
      }

      &.green {
        color: $color-white;
        background: $background-green;
      }
    }
  }
}
