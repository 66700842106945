.header {
  position: relative;
  display: block;
  width: 100%;

  a {
    color: $text-color;

    &:hover,
    &:active {
      color: $brand-primary;
    }
  }

  &[style*="background-image"] {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    @media (min-width: $grid-float-breakpoint + 1) {
      height: 0;
      padding-bottom: 56.25%;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      content: "";
      background: $color-black;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.05;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      content: "";
      background: linear-gradient(
        to bottom,
        rgb(0 0 0 / 0.65) 10%,
        rgb(0 0 0 / 0) 100%
      );
    }

    @media (min-width: $grid-float-breakpoint + 1) {
      .header__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    a {
      color: $color-white;

      &:hover,
      &:active {
        color: $brand-primary;
      }
    }

    .header__menu--account {
      .menu-item {
        border-color: $color-white;

        a,
        button {
          color: $color-white;

          &:hover,
          &:active {
            color: $brand-primary;
            background: $color-white;
          }
        }
      }
    }

    .logo {
      .logo-icon {
        color: $color-white;
      }
    }

    .menu-toggle {
      color: $color-white;
    }
  }

  &__inner {
    position: relative;
    z-index: 10;
    font-size: 0;
  }

  .top-bar {
    position: relative;
    z-index: 10;
    width: 100%;
    padding: 2.5rem;

    @media (max-width: $screen-lg-min) {
      padding: 2.5rem;
    }
  }

  .logo {
    display: inline-block;
    width: 29rem;
    height: 8rem;
    vertical-align: middle;

    @media (width <= 1360px) {
      width: 24rem;
      height: 6rem;
    }

    @media (max-width: $screen-lg-min) {
      width: 20rem;
      height: 5rem;
    }

    @media (max-width: $screen-sm-min) {
      width: 18.4rem;
      height: 3.5rem;
    }

    .logo-icon {
      width: 100%;
      height: 100%;
      color: $text-color;

      &.mobile-logo {
        display: none;

        @media (max-width: $screen-xs-max) {
          display: inline-block;
        }
      }

      &.desktop-logo {
        @media (max-width: $screen-xs-max) {
          display: none;
        }
      }
    }
  }

  .main-menu {
    display: inline-block;
    width: calc(100% - 29rem);
    text-align: right;
    vertical-align: middle;

    @media (width <= 1360px) {
      width: calc(100% - 24rem);
    }

    @media (max-width: $screen-lg-min) {
      width: calc(100% - 20rem);
    }

    @media (width <= 1025px) {
      display: none;
    }
  }

  &__menu {
    display: inline-block;
    padding: 0 1.5rem 0 0;
    margin: 0;
    vertical-align: middle;
    list-style: none;

    .menu-item {
      position: relative;
      display: inline-block;

      @media (width <= 1360px) {
        font-size: 1.6rem;
      }

      a {
        display: inline-block;
        padding: 1.5rem 1.7rem;
        font-size: 1.8rem;
        font-weight: $medium-font;
        text-decoration: none;
        transition: all 0.3s ease;

        @media (min-width: $grid-float-breakpoint) {
          white-space: nowrap;
        }

        @media (width <= 1360px) {
          padding: 1.3rem 1.4rem;
        }

        @media (max-width: $screen-lg-min) {
          padding: 1.3rem 1.2rem;
          font-size: 1.7rem;
        }
      }

      &:not(:last-of-type) {
        &::after {
          position: absolute;
          top: calc(50% - 0.2rem);
          right: -0.2rem;
          display: block;
          width: 0.4rem;
          height: 0.4rem;
          content: "";
          background: $brand-primary;
          border-radius: 50%;
        }
      }

      &__sub {
        position: absolute;
        top: 100%;
        left: 50%;
        padding: 0;
        list-style: none;
        background: $color-white;
        border-radius: $border-radius;
        box-shadow: 0 0 0.5rem 0 rgba($color-black, 0.2);
        opacity: 0;
        transition: opacity 0.5s ease;
        transform: scale(0, 0) translate(-50%, 0);
        transform-origin: top center;

        .sub-menu-item {
          position: relative;
          z-index: 4;
          padding: 0;
          background: $color-white;
          border-bottom: 0.1rem solid $border-color;

          &:first-of-type {
            border-radius: $border-radius $border-radius 0 0;
          }

          &:last-of-type {
            border-bottom-color: transparent;
            border-radius: 0 0 $border-radius $border-radius;
          }

          a {
            width: 100%;
            padding: 1.5rem 3rem 1.3rem;
            color: $text-color;
            text-align: center;
          }

          &:hover,
          &:active {
            background: $brand-primary;

            a {
              color: $color-white;
            }
          }
        }

        &::before {
          position: absolute;
          top: -0.8rem;
          left: calc(50% - 0.8rem);
          z-index: 3;
          display: block;
          width: 1.6rem;
          height: 1.6rem;
          content: "";
          background: inherit;
          border-color: transparent transparent $color-white;
          border-style: solid;
          box-shadow: 0 0 0.5rem 0 rgba($color-black, 0.2);
          transform: rotate(45deg);
        }
      }

      &:hover {
        .menu-item__sub {
          opacity: 1;
          transform: translate(-50%, 0);
        }
      }
    }

    &--account {
      display: inline-block;
      padding: 0;
      margin: 0;
      vertical-align: middle;

      .menu-item {
        display: inline-block;
        padding: 0;
        font-size: 1.8rem;
        vertical-align: middle;
        border: 0.2rem solid $brand-primary;
        transition: all 0.3s ease;

        @media (width <= 1360px) {
          font-size: 1.6rem;
        }

        &:not(:last-of-type) {
          border-right-width: 0;
        }

        &:first-of-type {
          border-radius: $border-radius 0 0 $border-radius;
        }

        &:last-of-type {
          border-radius: 0 $border-radius $border-radius 0;
        }

        a,
        button {
          display: inline-block;
          max-height: 4.7rem;
          padding: 1.4rem 1.5rem 1.3rem;
          font-weight: $medium-font;
          color: $brand-primary;
          text-decoration: none;
          background: transparent;
          transition: all 0.3s ease;

          @media (width <= 1360px) {
            padding: 1.3rem 1.3rem 1.1rem;
          }

          @media (max-width: $screen-lg-min) {
            padding: 1.1rem 0.7rem 1rem;
          }

          &:hover {
            color: $color-white;
            background: $brand-primary;
          }

          &.log-out {
            position: relative;
            display: block;
          }
        }
      }
    }
  }

  .menu-toggle {
    position: absolute;
    top: 50%;
    right: 2.5rem;
    width: 3rem;
    height: 2.3rem;
    padding: 0;
    color: $text-color;
    background: none;
    border: 0 none;
    transform: translate(0, -50%);

    &:focus {
      outline: 0 none;
      box-shadow: none;
    }

    @media (width >= 1025px) {
      display: none;
    }

    &__inner {
      &,
      &::before,
      &::after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 0.3rem;
        background: currentColor;
        border-radius: $border-radius;
      }

      & {
        top: 0;
      }

      &::before {
        top: 1rem;
        content: "";
      }

      &::after {
        top: 2rem;
        content: "";
      }
    }
  }
}

.page {
  &:not(.home) {
    .header {
      .logo {
        .logo-icon {
          &.mobile-logo {
            @media (max-width: $screen-xs-max) {
              svg {
                .st1 {
                  fill: $dark-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
