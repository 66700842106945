.info-section {
  padding: 8rem 0;
  text-align: center;

  &.orange {
    .icon-in-circle {
      color: $brand-primary;
      border-color: $brand-primary;

      .icon {
        color: $brand-primary;
      }
    }
  }

  &.red {
    .icon-in-circle {
      color: $color-red;
      border-color: $color-red;

      .icon {
        color: $color-red;
      }
    }
  }
}
