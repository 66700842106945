.property-need-modal {
  .modal__body {
    position: relative;
    overflow: hidden;

    img {
      max-height: 150px;
    }
  }

  p {
    line-height: 1.4;
  }

  .modal__header {
    .icon-container {
      background: $background-green;
    }
  }

  &.theme-blue {
    .modal__header {
      .icon-container {
        background: $logo-blue;
      }
    }
  }
}
