.reset-password-form {
  max-width: 65rem;
  margin: 4rem auto 0;

  @media (min-width: $screen-md-min) {
    form {
      display: flex;
    }

    .form-input {
      flex: 2;
      margin-right: 2.5rem;
    }

    .form-submit {
      flex: 1.2;
    }
  }
}
