.confirm-modal {
  &__inner {
    position: relative;
    overflow: hidden;
  }

  .heading {
    display: inline-block;
    width: 100%;
    margin: 0 0 2.4rem;
    font-size: 2.8rem;
    font-weight: $medium-font;
    line-height: 1;
    color: $text-second-color;
    text-align: center;
    vertical-align: middle;
  }

  .modal__frame {
    max-width: 44rem;
    padding-bottom: 0.5rem;
  }

  &__actions {
    text-align: center;

    .btn {
      margin: 0 0.75rem;
    }
  }

  .text-content {
    margin: 0 0 2rem;
    font-size: 1.4rem;
  }
}
