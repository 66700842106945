.property-details-tab {
  font-size: 1.7rem;

  .label {
    display: inline-block;
    margin: 0 0.5em 0 0;
    font-weight: $medium-font;
    vertical-align: top;
  }

  .value {
    display: inline-block;
    vertical-align: top;
  }

  .details-row {
    margin: 0 0 1rem;

    p {
      line-height: 1.4;
    }

    & + .col-sm-6 {
      margin-top: 1.5rem;

      & + .col-sm-6 {
        margin-top: 1.5rem;
      }
    }
  }

  .column {
    margin-top: 2.5rem;

    .heading {
      margin: 0 0 2.5rem;
      font-size: 2.2rem;
    }
  }
}
